/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {ACCESS_TYPES, overrideApiFeatureFlags} from '../modules/DebugModules/AccessOverrides/common';
import type {AEMInstance, AEMInstanceOptions, Sandbox} from '@adobe/exc-app/user';
import {AgreementOptions, OPERATIONS} from '@adobe/exc-app/agreements';
import appCacheService from './CacheService';
import AppHtmlService from './AppHtmlService';
import type {AppSandbox, OutgoingMessage, WorkspaceMenu} from '../models/AppSandbox';
import {BlockNavigationService, getBlockNavigation} from './BlockNavigationService';
import {ButtonDefinition, Events, getErrorMessage} from '@exc/shared';
import {CacheEntry, CacheScope} from '@adobe/exc-app/cache';
import {cleanSidenavUrls, updateWorkspaceURL} from '../utils/apiUtils';
import type {ConsentPermissions} from '@adobe/exc-app/consent';
import type {ContextFetchResponse, CoreContext, ExtendedConfigProxy} from '../models/Context';
import dataPrefetchService from './DataPrefetchService';
import eventObserver from '../modules/EventObserver';
import type {FeatureFlagsById} from '../models/FeatureFlags';
import {getBootstrap} from './BootstrapService';
import {
  getCustomImsData,
  getDiscoverySource,
  getSpaApp,
  isValidCustomImsToken,
  setMetricsApplication
} from '../utils/appSandboxUtils';
import type {GetFlagsWithOptions} from '@adobe/exc-app/featureflags';
import {getHistoryType, waitForGainsightInit} from '../utils';
import {getQueryParams} from '@exc/url/query';
import {hashToPath, splitPath} from '@exc/url';
import {HISTORY} from '../models/Solution';
import type {JiraFeedback} from '@adobe/exc-app/helpcenter';
import type {LandingPage, SolutionOrService} from '@adobe/exc-app/shell';
import {Level} from '@adobe/exc-app/metrics/Level';
import {MessageService} from './MessageService';
import metrics from '@adobe/exc-app/metrics';
import {onConsent} from '../utils/consent';
import type {OutgoingConfiguration} from './AppConfigureService';
import type {Parameters} from '@adobe/exc-app/permissions';
import permissionService from './PermissionService';
import type {PrefetchOptions} from '@adobe/exc-app/network';
import {PROVIDERS} from '@adobe/exc-app/featureflags';
import type PulseConfiguration from '@exc/pulse-sdk/lib/types/model/Configuration';
import type {Notification as PulseNotification, UserProfile} from '@exc/pulse-sdk/lib/types/model/Request';
import type PulseUNSService from '@exc/pulse-sdk/lib/types/PulseUNSService';
import {queueMetrics} from '@exc/auth';
import type {Session} from '@adobe/exc-app/session';
import sessionService from './SessionService';
import type {Parameters as SettingsParameters} from '@adobe/exc-app/settings';
import type {SrcDocOptions} from '@adobe/exc-app/modules';
import {storage} from '@exc/storage';
import type {ToastInfo} from '../models/Common';
import type {UnifiedShellConfig} from '../models/UnifiedShellConfig';
import {updatePath} from '@exc/url/pathUpdate';

const scopedEvents: Record<string, string> = {
  helpCenter: Events.CUSTOM_HELP_BUTTON_CLICK,
  helpCenterResource: Events.CUSTOM_HELP_BUTTON_CLICK,
  userProfile: Events.CUSTOM_PROFILE_BUTTON_CLICK
};

const validConfigKeys = new Set([
  'appContainer',
  'coachMark',
  'customEnvLabel',
  'customSearch',
  'digitalData',
  'helpCenter',
  'hero',
  'modal',
  'nps',
  'onCustomButtonClick',
  'showLanguagePicker',
  'showRolesPicker',
  'sidenav/visible',
  'sidenav/collapsed',
  'sidenav/config',
  'solution'
]);

export const SECONDARY_PROXY_CONFIGS: (keyof ExtendedConfigProxy)[] = ['spinner'];
export const SECONDARY_IGNORED_EVENTS = [
  Events.AFTER_PRINT_HANDLER,
  Events.BEFORE_PRINT_HANDLER,
  Events.COMPONENT_OPEN,
  Events.CUSTOM_HERO_CLICK,
  Events.HISTORY,
  Events.OPEN_HELP_CENTER,
  Events.PRINT_NOW,
  Events.SKIP_MENU_SET_LIST,
  Events.TOAST_SUPRESSED,
  Events.TRIGGER_NPS
];

interface ApiMessage {
  id: string;
  method: string;
}

type DataContext = CoreContext & OutgoingConfiguration & ContextFetchResponse;
type DataResponse = DataContext[keyof DataContext] | undefined;

type StringInput = ApiMessage & {data: string};
type DataInput = ApiMessage & {data: string, type: keyof DataContext};
type FlagsWithOptionsInput = ApiMessage & {data: GetFlagsWithOptions, provider?: PROVIDERS};
type AEMInstancesInput = ApiMessage & {data?: AEMInstanceOptions};
type MultiStringInput = ApiMessage & {data: string[]};
type FlexibleStringInput = StringInput | MultiStringInput;
type PrefetchInput = ApiMessage & {
  key: string;
  method: string;
  options?: PrefetchOptions
};
type ConsentPermissionsInput = ApiMessage & {data: ConsentPermissions};
type AgreementsEvent = {data: {key: string, operation: OPERATIONS, options: AgreementOptions}};

type apiHandler<T extends ApiMessage> = (value: T, app: AppSandbox) => void;

export class APIProcessingService {
  private readonly metrics = metrics.create('exc.core.services.APIProcessingService');
  private readonly messageService: MessageService;
  private readonly warnedConfigs = new Set<string>();
  private readonly blockNavigationService: BlockNavigationService;
  private pendindDataRequests: Record<string, DataInput> = {};
  private queuedDataEvents: ((app: AppSandbox) => void)[] = [];
  private toastsSupressed: ToastInfo[] = [];
  private searchOpenState?: boolean;

  constructor(messageService: MessageService) {
    this.messageService = messageService;
    this.blockNavigationService = getBlockNavigation();
    // Pulls the existing error toasts from local storage. `this.toastsSupressed`
    // is updated in real-time and then pushed back into storage. This protects
    // us against concurrency issues.
    storage.local.get<ToastInfo[]>('shellToasts').then(storedToasts => (this.toastsSupressed = storedToasts || []));
  }

  /**
   * Unblocks navigation (If blocked).
   */
  public unblockNavigation() {
    this.blockNavigationService.blockNavigation(false);
  }

  /**
   * Send configuration from the iframe (AppSandbox) to Unified Shell (Core component).
   * @param config - Updated Configuration
   * @param app - AppSandbox object.
   */
  public setProxiedConfiguration(config: Partial<ExtendedConfigProxy>, app: AppSandbox): void {
    const {context, metricsAppId} = app;
    if (app.secondary) {
      // Secondary (SandboxInner) are restricted to certain configs only.
      config = SECONDARY_PROXY_CONFIGS.reduce<Partial<ExtendedConfigProxy>>(
        (accumulator, key: keyof ExtendedConfigProxy) => {
          key in config && (accumulator[key] = config[key]);
          return accumulator;
        }, {});
    }
    // if digitalData is present, convert back to object before sending to metrics
    config.digitalData && (config.digitalData = JSON.parse(config.digitalData));
    this.metrics.event('Sandbox.setProxiedConfiguration', config);
    Object.keys(config).forEach(key => {
      const value = config[key as keyof ExtendedConfigProxy];
      switch (key) {
        case 'customButtons':
          config.onCustomButtonClick = (clickValue: ButtonDefinition) => this.messageService.sendMessage({
            type: scopedEvents[clickValue.scope],
            value: clickValue
          }, app);
          break;
        case 'feedback':
          if (value.type === 'custom') {
            app.customFeedback = true;
          }
          break;
        case 'logoutUrl':
          context.setLogoutUrl(typeof value === 'string' ? {url: value} : value);
          break;
        case 'preventDefaultCombos':
          eventObserver.setPreventDefaultCombos(value);
          break;
        case 'pulse/button':
          config.pulse = {
            ...(config.pulse || {}),
            button: {
              callback: () => this.messageService.sendMessage({type: Events.PULSE_BUTTON}, app),
              label: value.label
            }
          };
          break;
        case 'pulse/count':
          config.pulse = {
            ...(config.pulse || {}),
            count: value
          };
          break;
        case 'sidebar':
          // This is now deprecated in favor of the sidenav config, which should be used instead.
          // Remove this once all solutions have been transitioned to sidenav.
          value?.config?.menu && cleanSidenavUrls(value.config.menu, app);
          break;
        case 'sidenav/config':
          value?.menu && cleanSidenavUrls(value.menu, app);
          break;
        case 'solution':
          config.hero = value;
          break;
        case 'spinner':
          app.setSpinner(value);
          break;
        case 'subOrgs':
          this.metrics.info(`subOrgs set by solution ${metricsAppId}`);
          break;
        case 'title':
          app.titleService?.update(value);
          break;
        case 'unloadPromptMessage':
          this.blockNavigationService.unloadPromptMessage = value;
          break;
        case 'workspaces':
          (value as WorkspaceMenu[]).forEach(menu => updateWorkspaceURL(menu, app));
          break;
        default: {
          if (!validConfigKeys.has(key)) {
            // Delete any keys that do not match the above keys or keys in valid set so
            // Core state isn't filled with unneeded states and states don't get overridden
            delete config[key as keyof ExtendedConfigProxy];
            this.metrics.info(`Sandbox: unknown shell configuration ${key}`);
          }
        }
      }
    });
    // secondary sandboxes (i.e. SandboxInner) should not update Unified Shell config
    app.secondary || context.setConfigStates(config);
  }

  /**
   * Function to process the beta agreement get, clear, and show APIs
   * @param app - AppSandbox object
   * @param value - Message payload
   */
  private agreementHandler = (app: AppSandbox, value: AgreementsEvent) => {
    const {data: {key, operation, options}} = value;
    const agreementKey = options?.shared ? key : `${app.appId}-${key}`;
    const optionsArgument = operation === OPERATIONS.SHOW ? options : undefined;
    app.context.agreements?.[operation](agreementKey, optionsArgument)
      .then(resp => this.fulfillEvent(value, resp, app))
      .catch(e => this.rejectEvent(value, e, app));
  };

  /**
   * Process and respond to messages coming from the iframe (AppSandbox).
   * @param type - Message type
   * @param value - Message payload
   * @param app - AppSandbox object.
   * @param lastConfiguration - Last configuration sent to the iframe.
   */
  public handleMessage(type: string, value: any, app: AppSandbox, lastConfiguration?: OutgoingConfiguration): void {
    const {appId, context, metricsAppId, solutionConfig: {hideTenant}} = app;
    const sendMessage = <T>(message: OutgoingMessage<T>) => this.messageService.sendMessage(message, app);

    if (app.secondary && SECONDARY_IGNORED_EVENTS.includes(type)) {
      // These events do not apply to secondary sandboxes (i.e. SandboxInner), ignore.
      return;
    }

    switch (type) {
      case Events.ACTIVE:
        eventObserver.handleIframeActive();
        break;
      case Events.AEM_INSTANCES:
        this.aemInstanceHandler(value, app);
        break;
      case Events.AFTER_PRINT_HANDLER:
        context.setConfigStates({
          afterPrintHandler: () => sendMessage({type: Events.AFTER_PRINT_HANDLER})
        });
        break;
      case Events.AGREEMENTS:
        this.agreementHandler(app, value);
        break;
      case Events.AI_MESSAGE:
        document.dispatchEvent(new CustomEvent('aiMessageIncoming', {detail: value}));
        break;
      case Events.APP_CACHE:
        this.appCacheHandler(value, app);
        break;
      case Events.APP_DATA:
        this.appApiHandler(value, app);
        break;
      case Events.AUTH_EXPIRATION:
        queueMetrics('API Processing Auth Expiration', {data: {event: 'AUTH_EXPIRATION', value}});
        context.onSignOut(true, 'User timed out');
        break;
      case Events.BEFORE_PRINT_HANDLER:
        context.setConfigStates({
          beforePrintHandler: () => sendMessage({type: Events.BEFORE_PRINT_HANDLER})
        });
        break;
      case Events.BLOCK_NAVIGATION:
        // Prevent navigation blocking for tenantless solutions since it is not supported
        value && !hideTenant && this.blockNavigationService.blockNavigation(value.enabled, value.options);
        break;
      case Events.CLICK:
      case Events.FOCUS:
        document.dispatchEvent(new CustomEvent('sandboxClick'));
        break;
      case Events.CLIPBOARD_WRITE:
        navigator.clipboard.writeText(value.msg).catch(
          () => this.metrics.error(`Sandbox: handlePostMessage failed to write to clipboard ${value.msg}, appId: ${appId}`)
        );
        break;
      case Events.COMPONENT_CLOSE:
        context.toggleComponentModal(false);
        break;
      case Events.COMPONENT_OPEN: {
        setMetricsApplication(metricsAppId, app.solutionConfig, undefined, value?.component);
        context.toggleComponentModal(true, value, data => {
          this.messageService.sendMessage({type: Events.COMPONENT_CLOSE_COMPLETE, value: data}, app);
          setMetricsApplication(metricsAppId, app.solutionConfig);
        });
        break;
      }
      case Events.CONSENT_GET:
        this.consentApiHandler(value, app);
        break;
      case Events.CONSENT_UPDATE:
        context.updateConsent(value);
        onConsent(value, this.metrics);
        break;
      case Events.CUSTOM_HERO_CLICK:
        context.setConfigStates({
          customHeroClick: () => sendMessage({type: Events.HERO_CLICK})
        });
        break;
      case Events.CUSTOM_LEFT_NAV_CLICK:
        context.setConfigStates({
          customLeftNavClick: (isOpen: boolean) => sendMessage({type: Events.CUSTOM_LEFT_NAV_CLICK, value: isOpen}),
          leftNavCloseOnSelection: value
        });
        break;
      case Events.DATA_PREFETCH:
        this.dataPrefetchHandler(value, app);
        break;
      case Events.DATA_REQUEST:
        this.sendDataResponse(value, app, lastConfiguration);
        break;
      case Events.DEPRECATION_WARNING:
        this.sendDeprecationWarning(value, app);
        break;
      case Events.EXC_PULSE:
        this.pulseHandler(value, app);
        break;
      case Events.EXC_SETTINGS:
        this.settingsHandler(value, app);
        break;
      case Events.FEATURE_FLAGS:
        this.featureFlagsHandler(value, app);
        break;
      case Events.FEEDBACK_CONFIG:
        context.enableFeedback(value);
        break;
      case Events.FULFILLABLE_ITEMS: {
        const {fulfillableItems, imsOrg} = context;
        this.fulfillEvent(value, fulfillableItems?.[imsOrg]?.[value.data] || [], app, false);
        break;
      }
      case Events.GAINSIGHT_INIT:
        this.waitForGainsightHandler(value, app);
        break;
      case Events.GENERATE_SUBORG: {
        const {overrides, productContext} = value.data;
        let subOrg;
        try {
          subOrg = context.getSubOrgFromProductContext({...productContext, ...(overrides || {})});
        } catch (err) {
          this.metrics.error('Could not generate sub-org', {error: getErrorMessage(err), overrides, productContext});
        }
        this.fulfillEvent(value, subOrg, app, false, 'unable to generate sub-org');
        break;
      }
      case Events.EXTENDED_SHELL_INFO:
        context.getShellConfigurationExtended().then(shellResponse =>
          this.fulfillEvent(value, shellResponse, app, false, 'unable to generate shellInfoExtended'));
        break;
      case Events.HISTORY:
        this.historyHandler(value, app);
        break;
      case Events.IFRAME_RELOAD: {
        const {cacheBust} = value;
        app.onFrameReload(cacheBust);
        break;
      }
      case Events.JIRA_SUBMIT:
        this.jiraFeedbackHandler(value, app);
        break;
      case Events.KEYPRESS:
        eventObserver.handleIframeKeyPress(value as KeyboardEvent);
        break;
      case Events.LOST_AUTH:
        queueMetrics('API Processing lost auth', {data: {event: 'LOST_AUTH', value}});
        // Validate the token, because it's probably expired
        this.metrics.event('Sandbox.lostAuth', {level: Level.ERROR}, {appId: metricsAppId, url: value});
        context.on401();
        break;
      case Events.MODULES_DATA:
        this.modulesApiHandler(value, app);
        break;
      case Events.NOT_FOUND:
        app.onNotFound();
        break;
      case Events.OPEN_HELP_CENTER:
        document.dispatchEvent(new CustomEvent('openHelpCenter', {detail: value}));
        break;
      case Events.PERMISSIONS:
        this.permissionsHandler(value, app);
        break;
      case Events.PRINT_NOW:
        sendMessage({type: Events.PRINT_NOW});
        break;
      case Events.RECENT_EVENT: {
        context.recentsService.handleRecentsEvent(value, context);
        break;
      }
      case Events.SESSION:
        this.sessionHandler(value, app);
        break;
      case Events.SHELL_SETTINGS:
        this.setProxiedConfiguration(value, app);
        break;
      case Events.SPA_SRC_DOC:
        this.srcDocHandler(value, app);
        break;
      case Events.SKIP_MENU_SET_LIST: {
        const {skipMenuList} = value;
        document.dispatchEvent(new CustomEvent('skipMenuList', {detail: skipMenuList || null}));
        break;
      }
      case Events.TOAST_SUPRESSED:
        this.toastsSupressed.splice(0, 0, [value, Date.now()]);
        storage.local.set('shellToasts', this.toastsSupressed);
        document.dispatchEvent(new CustomEvent('toastSupressed'));
        break;
      case Events.TRIGGER_NPS:
        document.dispatchEvent(new CustomEvent('triggerNps'));
        break;
    }
  }

  /**
   * Logs iframe usage of deprecated api to EIM.
   * @param key - Deprecated API being used.
   * @param app - AppSandbox object.
   */
  sendDeprecationWarning = (key: string, app: AppSandbox) => {
    const {context} = app;
    if (!this.warnedConfigs.has(key)) {
      this.metrics.event('DeprecationWarning', key, {level: Level.WARN});
      if (context.environment !== 'prod') {
        this.metrics.warn(
          `This method of configuring "${key}" is deprecated. ` +
          `Please use the up-to-date method. For more information, ` +
          `visit https://git.corp.adobe.com/exc/unified-shell/wiki/Unified-Shell-Deprecation-Guide-and-Statuses`
        );
      }
      this.warnedConfigs.add(key);
    }
  };

  /**
   * Rejects an incoming data request event.
   * @param value - Event data
   * @param error - Error description
   * @param app - App Sandbox object
   */
  private rejectEvent<T>(value: T, error: any, app: AppSandbox) {
    this.messageService.sendMessage({
      type: Events.DATA_FULFILL,
      value: {...value, error: getErrorMessage(error), rejected: true}
    }, app);
  }

  /**
   * Fulfills an incoming data event with a provided response. If the response
   * is valid (e.g. not undefined) or it's OK if it's empty, send the message.
   * If it's undefined or not allowed to be empty, send an error message.
   * @param value - Event data
   * @param response - Response data
   * @param app - App Sandbox object
   * @param allowEmpty - If false, empty response will trigger rejection
   * @param errorMessage - Error to show if response is empty.
   */
  private fulfillEvent<T, K>(
    value: T,
    response: K,
    app: AppSandbox,
    allowEmpty = true,
    errorMessage = 'Unknown error'
  ) {
    (response !== undefined || allowEmpty) ?
      this.messageService.sendMessage({type: Events.DATA_FULFILL, value: {...value, response}}, app) :
      this.rejectEvent(value, errorMessage, app);
  }

  /**
   * Handles the case where data has been requested before it is available.
   * Ensures that any remaining requests for data are fulfilled.
   */
  public processOpenDataRequests(app: AppSandbox, lastConfiguration?: OutgoingConfiguration) {
    const {context} = app;
    const updatedContext = {...(lastConfiguration || {}), ...context};
    Object.values(this.pendindDataRequests)
      .forEach(value => value.type in updatedContext && this.sendDataResponse(value, app, lastConfiguration));
  }

  /**
   * Responds to an incoming data message.
   * Queue message for future response if data not yet available.
   * @param value - Data request message
   * @param app - App Sandbox object
   * @param lastConfiguration - Last configuration sent to the iframe
   * @private
   */
  private async sendDataResponse(value: DataInput, app: AppSandbox, lastConfiguration?: OutgoingConfiguration) {
    const {id, type} = value;

    if (!lastConfiguration) {
      this.pendindDataRequests[id] = value;
      return;
    }

    const {context, customIms, metricsAppId} = app;
    const CONTEXT_FETCHED = new Set(['sandbox', 'sandboxes']);
    this.metrics.event('Sandbox.sendDataResponse', {appId: metricsAppId, type});
    let updatedContext = {...lastConfiguration, ...context};

    // Handle customIms explicitly because it is an override on the context and
    // we need to use the new data. If the custom IMS token data is not
    // available, we cannot send the response yet.
    if (customIms) {
      const data = getCustomImsData(app);
      if (!data) {
        this.pendindDataRequests[id] = value;
        return;
      }
      if (!isValidCustomImsToken(app)) {
        this.metrics.warn('sendDataResponse had invalid custom token data');
      }
      updatedContext = {...updatedContext, ...data};
    }

    this.pendindDataRequests[id] && delete this.pendindDataRequests[id];
    let response: DataResponse = updatedContext[type as keyof (OutgoingConfiguration & CoreContext)];

    // If the user has requested sandbox data wait for the response from
    // the context service.
    if (response === undefined && CONTEXT_FETCHED.has(type)) {
      try {
        const values = await context.contextFetchPromise;
        response = values?.[type as keyof ContextFetchResponse];
      } catch (error: any) {
        return this.rejectEvent(value, error, app);
      }
    }

    this.fulfillEvent(value, response, app, false, `${type} not found`);
  }

  /**
   * Process delayed incoming data messages.
   * @param app App Sandbox object
   */
  public processEventQueue(app: AppSandbox) {
    if (this.queuedDataEvents.length) {
      // Copy and reset the queue before processing.
      const eventsToProcess = this.queuedDataEvents;
      this.queuedDataEvents = [];
      eventsToProcess.forEach(fn => fn(app));
    }
  }

  /**
   * Queues incoming event if data is not yet available to fulfill it.
   * @param condition - Condition needed to process right away
   * @param handler - Method to process the event if its delayed (The callee)
   * @param value - Event data
   * @returns true if queued, false otherwise.
   */
  queueEvent<T extends ApiMessage>(condition: boolean, handler: apiHandler<T>, value: T): boolean {
    condition || this.queuedDataEvents.push((app: AppSandbox) => handler(value, app));
    return !condition;
  }

  /**
   * App api handler used to provide application metadata.
   * @param value - Event data, containing application name.
   * @param app - App Sandbox object
   */
  appApiHandler: apiHandler<StringInput> = (value, app) => {
    const {context: {shellInfo}} = app;
    const {data = ''} = value;

    if (!this.queueEvent(!!shellInfo, this.appApiHandler, value)) {
      const {landingpage, services, solutions} = shellInfo;
      const response: SolutionOrService | LandingPage | undefined = (services as (SolutionOrService | LandingPage)[])
        .concat(solutions).concat(landingpage).find(l => l.appId === data);
      this.fulfillEvent(value, response, app, false, `${data} not found`);
    }
  };

  waitForGainsightHandler: apiHandler<StringInput> = async (value, app: AppSandbox) => {
    const {context: {userConsent}} = app;
    try {
      if (!this.queueEvent(!!userConsent, this.waitForGainsightHandler, value)) {
        await waitForGainsightInit();
        this.messageService.sendMessage({type: Events.GAINSIGHT_INIT}, app);
      }
    } catch {
      if (userConsent?.gainsightUsageDataCollection === false) {
        this.messageService.sendMessage({type: Events.GAINSIGHT_INIT}, app);
        return;
      }
      this.metrics.event('Sandbox.noGainsightInit', {level: Level.ERROR});
      this.messageService.sendMessage(
        {type: Events.GAINSIGHT_INIT, value: {error: 'Gainsight did not initialize', rejected: true}}, app
      );
    }
  };

  aemInstanceHandler: apiHandler<AEMInstancesInput> = async (value, app) => {
    const {data: {filterByRepoParam = false} = {}} = value;
    const {context: {imsOrg}} = app;
    if (!this.queueEvent(!!imsOrg, this.aemInstanceHandler, value)) {
      try {
        const instanceMap = await import('../utils/aem-fallback').then(({getAEMInstances}) => getAEMInstances());
        const repo = getQueryParams()['repo'];
        let instances: AEMInstance[] = (instanceMap[imsOrg] || [])
          .map(instance => ({...instance, matchesRepoParam: instance.domain === repo}));
        if (filterByRepoParam) {
          instances = instances.filter(({matchesRepoParam}) => matchesRepoParam);
        }
        this.fulfillEvent(value, instances, app);
      } catch (err) {
        this.rejectEvent(value, err, app);
      }
    }
  };

  /**
   * Cache api handler for get/set/delete cache data.
   * @param value - Event data
   * @param app - App Sandbox object
   */
  appCacheHandler: apiHandler<ApiMessage & Record<string, any>> = async (value, app) => {
    const {appId, context, solutionConfig: {sharedCaches}} = app;
    const {imsOrg, sandbox, subOrg} = context;
    const {data, method} = value;
    const {scope = CacheScope.ORG, sharedCache} = data || {};
    let params = {...data, appId, context};

    const canProcess = !!(imsOrg &&
      (scope !== CacheScope.SANDBOX || sandbox) &&
      (scope !== CacheScope.SUBORG || subOrg));

    if (!this.queueEvent(canProcess, this.appCacheHandler, value)) {
      if (sharedCache && sharedCache !== appId) {
        if (!(sharedCaches || []).includes(sharedCache)) {
          // Application is not allowed to access this shared cache.
          this.rejectEvent(value, `${appId} is not allowed to access shared cache "${sharedCache}"`, app);
          return;
        }
        params = {...params, appId: sharedCache, sharedCache: undefined};
      }

      try {
        let response: CacheEntry<any> | undefined;
        switch (method) {
          case 'DELETE':
            await appCacheService.delete(params);
            break;
          case 'GET':
            response = await appCacheService.get(params);
            break;
          case 'POST':
            await appCacheService.set(params);
            break;
        }
        this.fulfillEvent(value, response, app);
      } catch (err) {
        this.rejectEvent(value, err, app);
      }
    }
  };

  consentApiHandler: apiHandler<ConsentPermissionsInput> = async (value, app) => {
    const {context: {userConsent}} = app;
    if (!this.queueEvent(!!userConsent, this.consentApiHandler, value)) {
      this.fulfillEvent(value, userConsent, app, false, `${value.data} not found`);
    }
  };

  /**
   * Modules api handler provides SPA pipeline apps/modules metadata.
   * @param value - Event data
   * @param app - App Sandbox object
   */
  modulesApiHandler: apiHandler<FlexibleStringInput> = (value, app) => {
    const {data} = value;
    const {solutions} = window.config as UnifiedShellConfig;
    const allParams = getQueryParams();
    type ResponseData = Record<string, {liveVersion: string}>;

    let modules: ResponseData = Object.keys(solutions).reduce(
      (obj, name) => ({...obj, [name]: {liveVersion: solutions[name].liveVersion}}), {}
    );

    if (data && data !== '*') {
      const appsToReturn = typeof data === 'string' ? [data] : data;
      modules = appsToReturn.reduce((obj: ResponseData, name: string) => ({...obj, [name]: modules[name]}), {});
    }

    Object.keys(allParams).forEach(param => {
      if (param.endsWith('_version')) {
        const [name] = param.split('_version');
        modules[name] && (modules[name] = {...modules[name], liveVersion: allParams[param]});
      }
    });

    this.fulfillEvent(value, modules, app);
  };

  /**
   * Prefetches data needed by application.
   * @param value - Event data
   * @param app - App Sandbox object
   */
  dataPrefetchHandler: apiHandler<PrefetchInput> = async (value, app) => {
    const {appId, context} = app;
    const {key, method, options} = value;

    if (!this.queueEvent(!dataPrefetchService.isContextMissing(key, context), this.dataPrefetchHandler, value)) {
      try {
        let response = {};
        if (method === 'GET') {
          response = await dataPrefetchService.getData(key, context, appId, options);
        }
        this.fulfillEvent(value, response, app);
      } catch (err) {
        this.rejectEvent(value, err, app);
      }
    }
  };

  /**
   * Provides feature flag values.
   * @param value - Event data
   * @param app - App Sandbox object
   */
  featureFlagsHandler: apiHandler<FlagsWithOptionsInput> = async (value, app) => {
    const context = {...app.context};
    const {accessOverridesEnabled, getFlagService, hashedAuthId} = context;
    const {data} = value;
    // Ensure the context is there and the helper is available before proceeding
    if (!getFlagService) {
      return;
    }
    const flagService = getFlagService(data.provider || PROVIDERS.LAUNCH_DARKLY);
    // The original API receives an array of the requested projectIds from LaunchDarkly
    // The new, extended API supports an an object with feature flag provider ids
    // and context options
    // Either way, since an array is an object in javascript, destructuring is valid
    // because the variables will be undefined if the object is not present
    const {config, sandbox} = data;
    const providerIds = 'clientIds' in data ? data.clientIds : data.projectIds;
    // Then this check will work to handle the feature flag provider ids we need
    const projects: string[] = Array.isArray(data) ? data : providerIds;
    // Support context values included in the API
    sandbox && (context.sandbox = sandbox as Sandbox);
    let response: FeatureFlagsById | undefined;
    if (!this.queueEvent(!flagService.isContextMissing(projects, context), this.featureFlagsHandler, value) && flagService) {
      try {
        response = await flagService.getFeatureFlags(projects, context, config);
        // If access overrides are enabled, we update the response to include any overrides
        if (accessOverridesEnabled && response) {
          const overrideKey = data?.provider === PROVIDERS.FLOODGATE ? ACCESS_TYPES.FLOODGATE : ACCESS_TYPES.FEATURE_FLAGS;
          response = overrideApiFeatureFlags(response, projects, hashedAuthId, overrideKey);
        }
      } catch (err) {
        return this.rejectEvent(value, err, app);
      }
      this.fulfillEvent(value, response, app);
    }
  };

  /**
   * Session management API for applications.
   * @param value - Event data
   * @param app - App Sandbox object
   */
  sessionHandler: apiHandler<ApiMessage & {data: Session}> = async (value, app) => {
    const {context, solutionConfig: {session: sessionConfig}} = app;
    const {discoveryService, imsOrg, imsProfile} = context;
    const {data: session, method} = value;

    if (!sessionConfig) {
      return this.rejectEvent(value, 'Session not configured', app);
    }

    if (!this.queueEvent(
      !!(discoveryService && imsProfile && imsOrg), this.sessionHandler, value
    ) && discoveryService) {
      try {
        let response: Session | undefined;
        const options = discoveryService.getDiscoveryOptions(app);
        switch (method) {
          case 'DELETE':
            await sessionService.invalidateSession(session, context, options);
            break;
          case 'GET': {
            const source = getDiscoverySource(app);
            response = await sessionService.getSession(context, options, source, discoveryService);
            break;
          }
          case 'POST':
            await sessionService.setSession(session, context, options);
            break;
        }
        this.fulfillEvent(value, response, app);
      } catch (err) {
        this.rejectEvent(value, err, app);
      }
    }
  };

  /**
   * SrcDoc API to get SPA HTML for nested srcdoc iframes
   * @param value - HTML Request options
   * @param app - AppSandbox object
   */
  srcDocHandler: apiHandler<ApiMessage & {data: SrcDocOptions}> = async (value, app) => {
    const {appId} = app;
    const {data: options} = value;
    const {spaName} = options;
    const spaDetails = getSpaApp(spaName);
    if (!spaDetails?.liveVersion) {
      return this.rejectEvent(value, `Can not find '${spaName}' live version information`, app);
    }
    try {
      const appHtmlService = new AppHtmlService(getBootstrap().activeCdn);
      const html = await appHtmlService.getHtml({...options, appId, liveVersion: spaDetails.liveVersion});
      return this.fulfillEvent(value, html, app);
    } catch (err) {
      this.metrics.error('Failed to get SPA HTML', {
        error: getErrorMessage(err),
        liveVersion: spaDetails.liveVersion,
        spaName
      });
      return this.rejectEvent(value, `Failed to fetch '${spaName}' HTML`, app);
    }
  };

  /**
   * Permissions handler to read permissions.
   * @returns Permissions response.
   * @param value
   * @param app - App Sandbox object
   */
  permissionsHandler: apiHandler<ApiMessage & {data: Parameters}> = async (value, app) => {
    const {
      appId,
      context: {imsProfile, imsOrg, sandbox, sandboxesAvailable},
      solutionConfig: {urlContext}
    } = app;

    if (urlContext?.key !== 'sandbox' || !sandboxesAvailable) {
      return this.rejectEvent(
        value,
        'Permissions Service is only available to apps and orgs with PALM sandboxes enabled',
        app
      );
    }

    if (!this.queueEvent(!!(imsProfile && sandbox), this.permissionsHandler, value)) {
      const {data, id} = value;
      try {
        const response = await permissionService.get(data, imsOrg, sandbox as Sandbox);
        this.metrics.info(`Sandbox: PermissionsHandler received response for ${id}, appId: ${appId}`);
        this.fulfillEvent(value, response, app);
      } catch {
        this.metrics.error(`Sandbox: PermissionsHandler failed to get response for ${id}, appId: ${appId}`);
        this.rejectEvent(value, 'Unable to fetch permissions', app);
      }
    }
  };

  /**
   * Returns a Pulse SDK instance.
   * @param app - App Sandbox object
   * @returns Pulse UNS service
   */
  private getPulseService = async (app: AppSandbox): Promise<PulseUNSService> => {
    const {context: {
      environment: env,
      imsToken: accessToken
    }} = app;
    const queryParams = getQueryParams();
    const {default: {getPulseService}} = await import('@exc/pulse-sdk');
    return getPulseService({authInfo: {accessToken}, env, queryParams} as PulseConfiguration);
  };

  /**
   * Pulse handler for sending notifications.
   * @param value - Notification data.
   * @param app - App Sandbox object
   */
  pulseHandler: apiHandler<ApiMessage & {data: PulseNotification[]}> = async (value, app) => {
    const {appId, context: {imsProfile, imsOrg: imsOrgId}} = app;
    const {data, method} = value;
    if (!this.queueEvent(!!(imsProfile && imsOrgId), this.pulseHandler, value)) {
      try {
        const userProfile = imsProfile as UserProfile;
        const pulseService = await this.getPulseService(app);
        const response = await pulseService.sendNotifications({
          appId, data, imsOrgId, method, userProfile
        });
        this.fulfillEvent(value, response, app);
      } catch (err) {
        this.metrics.error('Sandbox: PulseHandler failed to send notifications', {error: getErrorMessage(err)});
        this.rejectEvent(value, 'Failed to send notifications', app);
      }
    }
  };

  /**
   * Settings handler to read/write/delete settings.
   * @param value Params and required Keys and settings.
   * @param app App Sandbox object
   */
  settingsHandler: apiHandler<ApiMessage & {data: SettingsParameters<any>}> = async (value, app) => {
    const {appId, context: {imsProfile, settingsService}} = app;
    const {data, id, method} = value;
    if (!this.queueEvent(!!(imsProfile && settingsService), this.settingsHandler, value)) {
      this.metrics.info(`Sandbox: SettingsHandler received request for ${id}, appId: ${appId}`);
      try {
        const response = await settingsService.settingsHandler(data, method);
        this.metrics.info(`Sandbox: SettingsHandler received response for ${id}, appId: ${appId}`);
        this.fulfillEvent(value, response, app);
      } catch {
        this.metrics.error(`Sandbox: SettingsHandler failed for ${id}, appId: ${appId}`);
        this.rejectEvent(value, 'Settings call failed', app);
      }
    }
  };

  /**
   * Updates Unified Shell path with path updates coming from the iframed application.
   * @param value - Application path and title.
   * @param app - App Sandbox object.
   * @private
   */
  private historyHandler(value : {path: string, title?: string}, app: AppSandbox) {
    let {path} = value;
    const {title} = value;
    const {basePath, configURL, context, solutionConfig} = app;
    const {browserParamFilterList, sandbox: {history, pathPrefix}} = solutionConfig;
    const historyType = getHistoryType(solutionConfig);
    // If the path prefix of the application exists in the path being
    // provided by the application, remove it before continuing to process
    // the history change.
    // When env is dev:
    // If dev path prefix is an empty string, replace default path prefix as described above.
    // If dev path prefix is a nonempty string, replace dev path prefix as described above.
    const prefix = context.environment === 'dev' && pathPrefix?.dev || pathPrefix?.default;
    if (prefix && path.startsWith(prefix)) {
      path = path.replace(prefix, '');
    }
    const {absolutePaths = false, addParamsToHash = false} = (typeof history === 'object' && history.config) || {};
    // Hash history modifies the provided path to include the hash as part
    // of the path. For example, if `value.path` is `#something`, the
    // updated value will be `/something`. This keeps the URL tidy.
    if (historyType === HISTORY.HASH) {
      const {hash, pathname, search} = new URL(`${window.location.origin}${path}`);
      const pathFromHash = hash ? `/${hashToPath({hash})}` : '';
      path = `${pathname}${pathFromHash}`;
      // If addParamsToHash solution config property is set, add the search
      // params directly to the hash in the application's frame URL.
      addParamsToHash && (path += search);
    }
    // Splits the pathname with the pathname in the config to ensure the
    // correct path is preserved on the URL. For example, a pathname of
    // `/dev/sc/page.php` with a config path of `/dev/sc` should net a
    // path of `/page.php` added onto the `basePath`.
    path = absolutePaths ? path : splitPath(path, configURL?.pathname || '');
    // Only replace double slashes with a single if it's not after a :,
    // denoting that it's part of https://.
    const topPath = `${basePath}${path}`.replace(/([^:])\/{2,}/g, '$1/');
    updatePath(
      {path: topPath},
      true,
      true,
      context.contextKey,
      false,
      browserParamFilterList && new Set<string>(browserParamFilterList)
    );
    title && app.titleService?.update(title);
  }

  /**
   * Sends the global search state (open/closed) to the iframed application.
   * @param app - App Sandbox object
   */
  public sendCustomSearchValue(app: AppSandbox) {
    const {context: {customSearch: {enabled, open: searchOpenState}}} = app;
    // Only send event if the state of the search menu has changed
    if (enabled && searchOpenState !== this.searchOpenState) {
      this.messageService.sendMessage({type: Events.CUSTOM_SEARCH, value: searchOpenState}, app);
      this.searchOpenState = searchOpenState;
    }
  }

  jiraFeedbackHandler = async (feedbackConfig: JiraFeedback, app: AppSandbox) => {
    const {context: {environment, imsInfo, sandbox, subOrg}} = app;
    const jira = (await import('@exc/feedback')).default;
    jira.createJiraFeedback(feedbackConfig, {...imsInfo, environment, sandbox, subOrg});
  };
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  AllFlagsContext,
  FeatureFlagsById,
  FloodgateResponses,
  GetFlagsContext,
  GraphQLFeatureFlagResponse,
  GraphQLPayload
} from '../../models/FeatureFlags';
import type {CoreContext} from '../../models/Context';
import Emitter from '@exc/emitter';
import type {FeatureFlagConfig} from '@adobe/exc-app/featureflags';
import type {
  FeatureFlagPayload,
  FeatureFlagUser,
  GraphQLErrorInfo
} from '@exc/graphql/src/models/launchDarkly';
import metrics, {Level, Metrics} from '@adobe/exc-app/metrics';
import {PROVIDERS} from '@adobe/exc-app/featureflags';
import {staleWhileRevalidate} from '../../utils';
import unifiedShellSession from '../UnifiedShellSessionService';

/**
 * The FeatureFlagService
 */
export default abstract class FeatureFlagService extends Emitter {
  public readonly fetched = new Set<string>();
  public readonly metrics: Metrics;
  public readonly provider: PROVIDERS;
  public readonly queryDataKey: keyof GraphQLFeatureFlagResponse;
  public onboardedClientIds?: string[];
  public readonly serviceName: string;
  public readonly validProjectIds: string[];
  private readonly promises: Record<string, Promise<FeatureFlagsById | undefined>> = {};

  constructor(provider: PROVIDERS, queryDataKey: string, validProjectIds: string[]) {
    super();
    this.provider = provider;
    this.queryDataKey = queryDataKey as keyof GraphQLFeatureFlagResponse;
    this.validProjectIds = validProjectIds;
    this.serviceName = `${this.provider.replace(' ', '')}Service`;
    this.metrics = metrics.create(`exc.core.services.${this.serviceName}`);
  }

  /**
   * Checks if data exists at the corresponding key
   * @param data - Multiple project response from GraphQl
   * @param options - Request options that can contain the key for the response data
   * @returns The data if it includes the correct key or undefined
   * @private
   */
  private checkForResponseData(data: GraphQLFeatureFlagResponse, options: GetFlagsContext | AllFlagsContext): GraphQLFeatureFlagResponse | undefined {
    // Ensures the response has the keyed data we need
    if (data && (data[this.queryDataKey] || (data as FloodgateResponses)[(options as AllFlagsContext)?.alternateDataKey])) {
      return data;
    }
    return undefined;
  }

  /**
   * Creates a key that includes the feature flags id and specific key info
   * @param contextKey - A string including the required user key info (org or org/sandbox)
   * @param projectId - Id to identify which feature flags have been fetched with which contexts
   * @returns A concatenated string including all the necessary key indicators
   * @private
   */
  private createKey(contextKey: string, projectId?: string): string {
    const providerKey = this.provider.replace(' ', '').toLowerCase();
    return projectId ? `${providerKey}/${contextKey}/${projectId}` : `${providerKey}/${contextKey}`;
  }

  /**
   * Emit the change event on the service to notify listeners of new data.
   * @param response - Flattened feature flags by id .
   * @param forcibleProjectIds - List of project ids that were forcibly fetched.
   */
  private emitChange = (response: FeatureFlagsById, forcibleProjectIds: string[]): void => {
    const data: FeatureFlagsById = {};
    forcibleProjectIds.forEach(key => key in response && (data[key] = response[key]));
    Object.keys(data).length && this.emit('change', data);
  };

  /**
   * Provider specific cleans up features flags response.
   */
  abstract flattenFeatureFlags<T>(data: T): FeatureFlagsById;

  private getContextKey(options: GetFlagsContext | AllFlagsContext) {
    const {imsOrg, sandbox} = options as GetFlagsContext;
    // This allows us to store the response from Floodgate's API for all
    // possible feature flags by giving it a unique key that does not
    // rely on context values
    if ('contextCacheKey' in options) {
      return options.contextCacheKey;
    }
    // If there is a sandbox, we want to include that in the key
    // But there can be a sandbox object without the values we use
    // so we first make sure we have those values to avoid a cacheKey
    // with undefined values
    const hasSandboxValues = sandbox?.name && sandbox.region && sandbox.type;
    return hasSandboxValues ?
      `${imsOrg}/${sandbox.name}/${sandbox.region}/${sandbox.type}` :
      imsOrg;
  }

  /**
   * Returns an existing, cached response for feature flags.
   * Looks at the cache first, then at pending requests.
   * @param cacheKey - unique key set by the provider service (i.e. imsOrgId or imsOrgId/sandbox)
   * @returns An object of feature flags groups (projects or clients) with flattened feature flags
   * @private
   */
  private async getExistingResponse(cacheKey: string): Promise<FeatureFlagsById | undefined> {
    try {
      // Look for cached result and if there is one, return it
      // Otherwise, look for pending promise and return it
      return await unifiedShellSession.get('featureFlags', null, cacheKey) || this.promises[cacheKey];
    } catch (err) {
      this.metrics.warn(`Failed to get ${this.provider} response from cache or memory`, {err});
    }
  }

  /**
   * Fetches feature flags based on the provider's unique checks and options.
   */
  abstract getFeatureFlags(projectIds: string[], context: GetFlagsContext, config?: FeatureFlagConfig): Promise<FeatureFlagsById | undefined>;

  /**
   * Handles bulk request flow for feature flags by id, checking for a response
   * in cache or a pending promise. Otherwise fetches from GraphQL.
   * @param promise - The graphql promise to fetch feature flags from the correct provider
   * @param projectIds - Array of feature flag project or client ids to fetch
   * @param options - Context options to properly fetch data
   * @param contextKey - A string including the required user key info (org or org/sandbox, etc)
   * @returns An object of feature flags groups (projects or clients) with flattened feature flags
   * @public
   */
  public async getFlagData(
    fetchFeatureFlags: (payload: GraphQLPayload) => Promise<GraphQLFeatureFlagResponse | undefined>,
    projectIds: string[],
    options: GetFlagsContext | AllFlagsContext,
    forcibleProjectIds: string[] = [],
    config?: FeatureFlagConfig
  ): Promise<FeatureFlagsById | undefined> {
    // The `contextKey` is a string with related context values, like org or sandbox,
    // that is formatted and used for tracking, fetching, and the cache
    const contextKey = this.getContextKey(options);
    // Whereas the `cacheKey` includes the provider and the contextKey
    // We use to cacheKey to check for existing responses in cache but
    // maintain the provider-less `contextKey` to construct the fetched set later on
    const cacheKey = this.createKey(contextKey);
    // Step one: Get and validate any existing data before fetching from GraphQL
    const response = await this.getExistingResponse(cacheKey);
    // If the force property is set, we should remove the requested projects
    // from the cache if they exist and are in the allowed forcible projects.
    config?.force && projectIds
      .filter(project => forcibleProjectIds.includes(project))
      .forEach(project => response && delete response[project]);
    // Validate that the response has all the data that's been requested
    const missingFlags = this.getUncachedFlags(projectIds, response) || [];
    // Validate requested flags are fetched at least once per page load
    const unfetchedFlags = this.getUnfetchedFlags(projectIds, contextKey) || [];
    // We don't need to go through the staleWhileRevalidate workflow
    // every single time. If we have a fully validated response, return it
    if (response && !missingFlags.length && !unfetchedFlags.length) {
      return response;
    }

    // Step two: If we don't have a fully validated response,
    // construct a list of project/client ids to fetch in the graphql promise
    // If the cache or promise response is missing data, don't return it
    // It could be an empty object when the API fetches it
    // We reassign `response` so we don't return an empty/partial response
    const validatedResponse = !missingFlags.length ? response : undefined;
    // Construct a list of project/client ids and payload options to fetch in the graphql
    // promise including missing and unfetched projects/clients this session
    projectIds = Array.from(new Set(missingFlags.concat(unfetchedFlags)));
    const payload = this.getProviderPayload(projectIds, options);

    // Step three: Construct a promise to fetch the data from the GraphQL API
    const getGraphQLPromise = async (): Promise<FeatureFlagsById | undefined> => {
      const graphQLPromise = fetchFeatureFlags(payload)
        .then((graphQLResponse: GraphQLFeatureFlagResponse) => {
          // Our GraphQl package returns santized errors (and also handles Adobe io errors)
          if (graphQLResponse?.errors) {
            this.logResponseErrors(contextKey, graphQLResponse.errors, projectIds);
          }
          // It's possible for the response to also include usable data
          // If there is data, use it, otherwise return an empty object
          if (!this.checkForResponseData(graphQLResponse, options)) {
            return {};
          }
          const successResponse = this.processSuccessResponse(contextKey, graphQLResponse, projectIds);
          // Force the feature flags to change, which also emits an event for the change
          config?.force && this.emitChange(successResponse, forcibleProjectIds);
          return {...response, ...successResponse};
        })
        .finally(() => {
          delete this.promises[cacheKey];
        });
      this.promises[cacheKey] = graphQLPromise;
      return graphQLPromise;
    };
    return staleWhileRevalidate<FeatureFlagsById | undefined>(getGraphQLPromise, validatedResponse);
  }

  /**
   * Gets the specific options payload for the GraphQL feature flags promise.
   */
  abstract getProviderPayload(
    projectIds: string[],
    options: FeatureFlagUser<FeatureFlagPayload> | GetFlagsContext | AllFlagsContext
  ): GraphQLPayload;

  /**
   * Validates the flags requested against which ids have been fetched this session.
   * @param projectIds - Array of ids for feature flags requested
   * @param contextKey - A string of response context (org, org+sandbox)
   * @returns Ids for flags that have not been fetched this session
   * @private
   */
  private getUnfetchedFlags = (projectIds: string[], contextKey: string): string[] =>
    projectIds.filter((id: string) => !this.fetched.has(this.createKey(contextKey, id)));

  /**
   * Validates the flags requested against those in a response.
   * @param projectIds - Array of ids for feature flags requested
   * @param response - Response of feature flags by id
   * @returns Ids for missing data from the response
   * @private
   */
  private getUncachedFlags(projectIds: string[], response = {}): string[] {
    const cachedFlagKeys = Object.keys(response);
    return projectIds.filter((key: string) => !cachedFlagKeys.includes(key));
  }

  /**
   * Provider specific checks for required context values.
   */
  abstract isContextMissing(projectIds: string[], context: CoreContext): boolean;

  /**
   * Checks context data to see if the required data is missing.
   * @param context - Core Context
   * @returns False if context has all data needed, true otherwise.
   */
  public isCoreContextMissing(context: CoreContext): boolean {
    const {imsOrg, internal, locale} = context;
    // We want to check that internal is defined (not just false with !internal)
    // Our payload requires the following values
    return !imsOrg || typeof internal !== 'boolean' || !locale;
  }

  /**
   * Logs an error for background, non-blocking GraphQL failures.
   * @param contextKey - String with context info type like ims org
   * @param errors - array or graphql errors
   * @param projectIds - array of project/client ids that failed to fetch
   * @private
   */
  public logResponseErrors(contextKey: string, errors: GraphQLErrorInfo[], projectIds: string[]) {
    // The request failed for this key, so don't cache that response in the
    // fetched set. Now subsequent requests will re-fetch.
    projectIds.forEach(id => this.fetched.delete(this.createKey(contextKey, id)));
    const message = `GQL failed to fetch feature flags from ${this.provider}. ${errors.map(err => err.message).join(', ')}` || 'Unknown error';
    this.metrics.event(`${this.serviceName}.backgroundError`, {message}, {level: Level.ERROR});
  }

  /**
   * Cleans up the response, caches and returns it.
   * @param contextKey - unique key for type of feature flag query
   * @param graphQLResponse - Multiple project response from GraphQl
   * @param projectIds - Array of requested project/client ids
   * @returns An object of project/client ids with flattened feature flags
   */
  processSuccessResponse(
    contextKey: string,
    graphQLResponse: GraphQLFeatureFlagResponse,
    projectIds: string[]
  ): FeatureFlagsById {
    const flattenedFlags = this.flattenFeatureFlags(graphQLResponse);
    this.updateCache(contextKey, flattenedFlags, projectIds);
    return flattenedFlags;
  }

  /**
   * Handles caching reponses and tracking the recieved data this session.
   * @param contextKey - String with key data like ims org/sandbox
   * @param flags - Flattened feature flags by id
   * @param projectIds - array of project/client ids that failed to fetch
   * @public
   */
  public updateCache(contextKey: string, flags: FeatureFlagsById, projectIds: string[]): FeatureFlagsById {
    // When a successful request is made to get the latest data - Add the key/id
    // pair to the fetched set so subsequent requests do not make additional requests
    projectIds.forEach(id => this.fetched.add(this.createKey(contextKey, id)));
    // Add to the cache and log event based on the provider and key
    const cacheKey = this.createKey(contextKey);
    unifiedShellSession.update('featureFlags', flags, null, cacheKey, false);
    this.metrics.event(`${this.serviceName}.cacheUpdate`, {key: cacheKey});
    return flags;
  }

  /**
   * Validates the request ids against the valid ids for the provider.
   * Throws an error for requests (non bulk) that explicitly include
   * invalid ids.
   * @param projectIds - array of project/client ids
   * @returns Array of valid ids or an error
   * @public
   */
  public validateProjectIds(projectIds: string[]): string[] {
    const allowAll = this.validProjectIds.length === 0;
    // Support bulk API with ['*'] request as we require the actual
    // ids for data fetching, caching, and response formatting at the end
    const fetchAll = projectIds.length === 1 && projectIds[0] === '*';
    if (fetchAll) {
      projectIds = allowAll ? (this.onboardedClientIds || []) : this.validProjectIds;
    }
    // If there are no ids for this request, log an error
    if (!projectIds.length) {
      const message = 'Missing request id';
      this.metrics.event(`Feature flag validation error`, {ids: projectIds, message}, {level: Level.ERROR});
    }
    // Validate the request includes only valid ids
    // This does NOT include bulk API queries with ['*']
    // this does NOT include queries that do not enforce a project list like Floodgate (allowAll)
    if (!allowAll && !fetchAll && !projectIds.every(id => this.validProjectIds.includes(id))) {
      const message = 'Invalid request id';
      this.metrics.event(`Feature flag validation error`, {ids: projectIds, message}, {level: Level.ERROR});
      throw new Error(`${message} - ${projectIds.join(',')}`);
    }
    return projectIds;
  }
}

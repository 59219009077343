/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  AllFloodgateFlagsResponse,
  FloodgateBaseInput,
  FloodgateBaseInputPayload,
  FloodgateFeatureFlagsResponse,
  FloodgateInput,
  FloodgateInputPayload
} from '../models/floodgate';
import {gqlQuery} from '../index';

export const FLOODGATE_QUERY = `
  query fgFeatureFlagsQuery($payload: FeatureFlagInput!) {
    fgFeatureFlags(payload: $payload) {
      clientId
      featureFlags {
        release
        features
        featuresMeta {
          feature
          meta
        }
        release
      }
      status
    }
  }
`;

export const FLOODGATE_ALL_FLAGS_QUERY = `
  query allFgFeatureFlags($payload: FeatureFlagsInput!) {
    allFgFeatureFlags(payload: $payload) {
      clientId
      featureFlags {
        name
        status
        meta
      }
      status
    }
  }
`;

export const getAllFloodgateFeatureFlags = (floodgatePayload: FloodgateBaseInput): Promise<AllFloodgateFlagsResponse> =>
  gqlQuery<AllFloodgateFlagsResponse, FloodgateBaseInputPayload>(
    FLOODGATE_ALL_FLAGS_QUERY,
    {payload: floodgatePayload},
    {ignoreErrorsOnPaths: ['allFgFeatureFlags']}
  );

export const getFloodgateFeatureFlags = (floodgatePayload: FloodgateInput): Promise<FloodgateFeatureFlagsResponse> =>
  gqlQuery<FloodgateFeatureFlagsResponse, FloodgateInputPayload>(
    FLOODGATE_QUERY,
    {payload: floodgatePayload},
    {ignoreErrorsOnPaths: ['fgFeatureFlags']}
  );

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {DisplayTuxProps} from './models/TuxTypes';
import type {Metrics} from '@adobe/exc-app/metrics';

export const displayTuxWidget = async (props: DisplayTuxProps, metrics: Metrics) => {
  const {
    disabledApps,
    force,
    firstTimeUser,
    isLogin,
    settingsService,
    topLevelApp
  } = props;
  // If there is a `forcetux` query param we ALWAYS show the tux survey
  // and we skip any checks on display logic (but `processtux` query
  // param still runs through display logic)
  if (force) {
    return true;
  }
  // If the current app is in the disabled list AND there is NO force,
  // skip all data fetching and rendering
  if (!force && disabledApps.split(',').includes(topLevelApp)) {
    metrics.info('TUX displayTuxWidget early return, application is on the disabled list', {topLevelApp});
    return false;
  }
  // Otherwise use the survey service to determine if we need the UI
  const surveyService = (await import('./services/SurveyService')).default;
  return surveyService.shouldShowSurvey(topLevelApp, settingsService, isLogin, firstTimeUser);
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {DataDedupConfig} from '@adobe/exc-app/network/DataPrefetchContract';
import type {DataPrefetchService} from './DataPrefetchService';
import {getRecents as loadOlderRecents} from '@exc/graphql/src/queries/recents';
import {mergeValuesByUniqueId} from '../dataPrefetchContracts/dataPrefetchUtils';
import metrics, {Metrics} from '@adobe/exc-app/metrics';
import {RecentObjectData, Recents, RecentVariables} from '@exc/graphql/src/models/recents';
import {RECENTS_PREFETCH_KEY, recentsContract} from '../dataPrefetchContracts/recentsContract';
import {throttleQueue} from '@exc/shared';
import type {UserOrgContext} from '../models/Context';

const COOLDOWN_TIMEOUT = 10000;
const EVENT_TIMEOUT = 40000;
const SETTINGS_TIMEOUT = 5000;

export type StoreTimestampFn = (timestamp: number) => Promise<void>;

export default class RecentsService {
  private activeRequest = false;
  private readonly dataPrefetchService: DataPrefetchService;
  private eventFetchTimeout: ReturnType<typeof setTimeout> | null = null;
  private readonly metrics: Metrics;
  private queuedContext: UserOrgContext | undefined;
  private requestCooldown: ReturnType<typeof setTimeout> | null = null;
  private readonly reportToSettings: () => void;

  constructor(service: DataPrefetchService, callback: StoreTimestampFn) {
    this.metrics = metrics.create('exc.core.RecentsService');
    this.dataPrefetchService = service;
    this.reportToSettings = throttleQueue(
      () => callback(Date.now()),
      SETTINGS_TIMEOUT
    );
  }

  /**
   * Get recents from the DataPrefetchService. Context is set separately so that
   * the `handleRecentsEvent` doesn't need to know about it.
   * @param context - User/org context of the request.
   * @returns Array of recents or empty if there is an error or missing context.
   */
  public async getRecents(context: UserOrgContext): Promise<Recents | undefined> {
    // If there is an active request or still in the cooldown period, queue the
    // context to be triggered once it's available.
    if (this.activeRequest || this.requestCooldown) {
      this.queuedContext = context;
      return;
    }
    try {
      this.activeRequest = true;
      const res = await this.dataPrefetchService.getData<Recents>(RECENTS_PREFETCH_KEY, context);
      this.activeRequest = false;
      return res.value;
    } catch (e) {
      this.metrics.error('Error fetching recents', e);
    } finally {
      this.requestCooldown = setTimeout(() => {
        const ctx = this.queuedContext;
        this.queuedContext = undefined;
        this.requestCooldown = null;
        ctx && this.getRecents(ctx);
      }, COOLDOWN_TIMEOUT);
    }
  }

  /**
   * Handles recent events. A timeout is started upon receipt which is restarted
   * if it is called again before the timeout finishes.
   * @param event - The recents event data.
   * @param context - User/org context of the request.
   */
  public handleRecentsEvent(event: any, context: UserOrgContext) {
    this.reportToSettings();
    this.eventFetchTimeout && clearTimeout(this.eventFetchTimeout);
    this.eventFetchTimeout = setTimeout(() => {
      this.getRecents(context);
      this.eventFetchTimeout = null;
    }, EVENT_TIMEOUT);
  }

  /**
   * Loads older recents
   * @param currentRecents - recents currently in state
   * @param cursor - cursor to fetch older
   * @returns - complete recent result with one page of older recents and next cursor
   */
  public async getOlderRecents(currentRecents: RecentObjectData[], cursor: string): Promise<Recents> {
    const recentParams: RecentVariables = {
      acrossSandbox: true,
      cursor
    };
    try {
      const result = await loadOlderRecents(recentParams);
      const {cursor: newCursor = null, recents: olderRecents = []} = result.recent || {};
      const dedup = recentsContract.refreshData?.dedup;
      const {recents} = mergeValuesByUniqueId({recents: currentRecents}, {recents: olderRecents}, (dedup as DataDedupConfig));
      return {cursor: newCursor, recents};
    } catch (e) {
      this.metrics.error('Error fetching older recents', e);
      return {
        cursor: null,
        recents: currentRecents
      };
    }
  }
}

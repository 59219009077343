/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {BlockNavigationOptions} from '@adobe/exc-app/page';
import {history} from '@exc/router';
import {isOrgOrSubOrgChange} from '../utils';
import {NOOP} from '@exc/shared';
import {UnregisterCallback} from 'history';

export class BlockNavigationService {
  private unblock: UnregisterCallback | undefined;
  public unloadPromptMessage: string;
  private correctHash: string;
  private postCallback: (navigate: boolean) => void;
  private hasMovedBack = false;

  constructor(unloadPromptMessage?: string) {
    this.unloadPromptMessage = unloadPromptMessage ?? '';
    this.correctHash = '';
    this.postCallback = NOOP;
  }

  /**
   * Called by the HistoryBlockerDialog after navigation has been continued or cancelled
   * @param navigate - True to continue navigation, false to cancel
   * @param callback - Callback from history to continue or cancel navigation
   */
  public navigate(navigate: boolean, callback: (result: boolean) => void) {
    callback(navigate);
    this.postCallback?.(navigate);
  }

  /**
   * Handler for before unload event
   * @param e - Before Unload event
   */
  private unloadListener = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = this.unloadPromptMessage;
  };

  private hashChangeListener = (e: HashChangeEvent, options?: BlockNavigationOptions) => {
    // If navigation is cancelled, the hash is updated again back to the original URL, and this
    // listener is called again. To prevent a loop, the "correct" hash (the hash of the page blocking
    // was activated on) is saved. If the new URL has the same hash, allow the change to go through
    // without blocking. Or, if blocking is only on org change and there has not been an org change,
    // it should not be blocked. Update the hasMovedBack flag to prepare for the next location change.
    if (
      this.correctHash === window.location.hash ||
      options?.onOrgChangeOnly && !isOrgOrSubOrgChange(new URL(e.oldURL).hash, new URL(e.newURL).hash)
    ) {
      this.hasMovedBack = false;
      return;
    }
    e.preventDefault();

    this.postCallback = (navigate: boolean) => {
      const win = (window as any);
      // The user chose not to navigate, so the hash needs to be updated to return to the previous page
      if (!navigate) {
        // The navigation object contains all the pages in the browser's navigation history. If the current entry
        // is not the last entry in the list (found by comparing the current entry's index to the number of entries)
        // then the navigation was backward in history, and we need to go forward to return to the correct page.
        // The Navigation API is experimental and cannot be used on all browsers
        // (https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API)
        if (win.navigation) {
          // Do not set hasMovedBack if win.navigation exists
          if (win.navigation.entries().length !== win.navigation.currentEntry.index + 1) {
            history.goForward();
          } else {
            history.goBack();
          }
        } else {
          // Hash history has been called once for this change
          this.hasMovedBack = true;
          history.goBack();
        }
      } else {
        this.blockNavigation(false);
      }
      this.postCallback = NOOP;
    };
  };

  private hashChangeHandler: ((e: HashChangeEvent) => void) = NOOP;

  /**
   * Block/Unblock navigation
   * @param enabled - True to block, false to unblock
   * @param options - Additional options
   */
  public blockNavigation(enabled: boolean, options?: BlockNavigationOptions) {
    if (!enabled) {
      window.removeEventListener('beforeunload', this.unloadListener);
      window.removeEventListener('hashchange', this.hashChangeHandler);
      this.postCallback = NOOP;
      this.hashChangeHandler = NOOP;
      this.unblock && this.unblock();
      this.correctHash = '';
      this.unloadPromptMessage = '';
      return;
    }

    this.correctHash = `#${history.location.pathname}`;

    this.unblock = history.block((location, action) => {
      if (action !== 'REPLACE') {
        const promptUser = (options?.onOrgChangeOnly) ?
          isOrgOrSubOrgChange(history.location.pathname, location.pathname) : true;
        if (promptUser) {
          this.postCallback = (navigate: boolean) => {
            if (navigate) {
              this.correctHash = `#${history.location.pathname}`;
            }
            this.postCallback = NOOP;
          };

          // Hash history treats all location changes as hash changes, so there is no way to tell apart a regular
          // navigation from a back button click. When the back button is used, it is treated the same as any other hash
          // change and history.goBack() is called. For a normal hash change, the new hash (location.pathname) would be
          // the same as the current location in history (the page we are trying to stay on). For a back button change,
          // the new hash would be that of the page two back in history. Therefore, if history.goBack() has been called for
          // a hash change and the new hash is not the same as the current page hash, we have moved back two entries in history
          // and need to move forward again to return to the correct page.
          if (`#${location.pathname}` === this.correctHash) {
            return;
          } else if (this.hasMovedBack && location.pathname !== history.location.pathname) {
            history.go(2);
            this.hasMovedBack = false;
          } else {
            return this.unloadPromptMessage;
          }
        }
      }
    });
    window.addEventListener('beforeunload', this.unloadListener);
    this.hashChangeHandler = (e: HashChangeEvent) => this.hashChangeListener(e, options);
    window.addEventListener('hashchange', this.hashChangeHandler);
  }
}

let blockNavigation: BlockNavigationService | undefined;

/**
 * Returns an instance of the Block Navigation service.
 */
export const getBlockNavigation = (unloadPromptMessage?: string): BlockNavigationService => {
  if (!blockNavigation) {
    blockNavigation = new BlockNavigationService(unloadPromptMessage);
  }
  return blockNavigation;
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {FetchScope} from '@adobe/exc-app/network';
import {gqlQuery} from '../index';
import type {LaunchDarklyFeatureFlagsResponse, LaunchDarklyInput} from '../models/launchDarkly';

export const MULTIPLE_PROJECT_FEATURE_FLAGS_QUERY = `
  query multipleProjectFeatureFlagsQuery($projectIds: [String], $userData: CustomUserOptions) {
    multiProjectFeatureFlags(projectIds: $projectIds, user: $userData) {
      user {
        key
        custom {
          locale
          sandboxName
          sandboxRegion
          imsOrgId
        }
      }
      projects {
        projectId
        featureFlags {
          name
          value
        }
      }
    }
  }
`;

export const getMultiProjectFeatureFlags = (ldMultiProjectInput: LaunchDarklyInput): Promise<LaunchDarklyFeatureFlagsResponse> =>
  gqlQuery<LaunchDarklyFeatureFlagsResponse, LaunchDarklyInput>(
    MULTIPLE_PROJECT_FEATURE_FLAGS_QUERY,
    ldMultiProjectInput,
    {scope: FetchScope.SANDBOX}
  );

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {checkCDN} from '../cdnConnect';
import type {
  DisplayTuxSurveyProps,
  ShowRolesModalInviteOptions,
  ShowUserConsentOptions
} from '../models/ShellModals';
import {displayTuxWidget} from '@exc/tux';
import {FlagValue} from '../models/LaunchDarkly';
import {getPathWithoutTenant, hashToPath} from '@exc/url';
import {getQueryValue} from '@exc/url/query';
import {getUserConsent} from '@exc/graphql/src/queries/userConsent';
import {OBJECTIVE_NAMES, ROLE_NAMES} from '@exc/shell/src/ShellRolesModal/RolesAndObjectives';
import type {OutboundConsentPermission} from '@exc/graphql/src/models/userConsent';
import {RELEASE_TYPE} from '../models/Solution';
import type {StoredUserRoles, UnifiedShellSettings} from '@exc/graphql/src/models/auth';
import type {UserRoles} from '@adobe/exc-app/RuntimeConfiguration';
import {wait} from '@exc/shared';

// Updates to the user consent modal UI and legal language should include an
// update to this timestamp to ensure users see any updates
// Use new Date().toISOString().replace(/Z$/, '+00:00') to align with graphql
const LAST_USER_CONSENT_UPDATE = '2021-04-16T18:20:49.276+00:00';

const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;

/**
 * Determine if Tux Survey should display
 */
export const displayTuxSurvey = async ({
  disabledApps,
  firstTimeUser,
  force,
  isLogin,
  locale,
  metrics,
  settings,
  settingsService,
  shellResponse,
  showToast,
  solutionConfig,
  translationService
}: DisplayTuxSurveyProps) => {
  // A unique visitor should not be given a TUX survey
  if (!force && firstTimeUser) {
    metrics.info('TUX displayTuxSurvey, first time user set for TUX', {settings});
    // No return here, the value is passed to TUX to set a new quarantine date
  }
  // Extract what we need from the solutionConfig
  const {appId, appRoot, parent, releaseType} = solutionConfig;
  // TUX surveys are scoped to the top level/parent app
  // In the future, we can add additional ids for API driven surveys
  // (i.e. `featureId` or `workflowId`)
  const topLevelApp = appRoot || appId;
  // TUX should NOT be triggered for apps in POC, Dev, Alpha, or Beta
  if (!force && (releaseType && releaseType !== RELEASE_TYPE.GA)) {
    return metrics.info('TUX displayTuxSurvey early return, non-GA app', {releaseType});
  }
  // SKIP FOR QA/STAGE TESTING && RETURN FOR PROD RELEASE
  // TUX should only display on prod environment
  // Unless there is a force query param
  // if (!force && environment !== 'prod') {
  //   return metrics.info('TUX displayTuxSurvey early return, non-prod environment', {environment});
  // }
  const tuxConfig = {
    appId,
    appParent: parent,
    appRoot,
    disabledApps,
    firstTimeUser,
    force,
    isLogin,
    locale,
    releaseType,
    settingsService,
    showToast,
    topLevelApp,
    translationService
  };
  const displayWidget = await displayTuxWidget(tuxConfig, metrics);
  // If the TUX survey SHOULD be shown, construct the
  // necessary props and config info for rendering
  if (displayWidget) {
    // Get the branding data from AppAssembly's shellResponse object
    const {landingpage, services, solutions} = shellResponse;
    const brandingInfo = services.concat(solutions).find(({appId: id}) => topLevelApp === id);
    return {
      brandingInfo: brandingInfo || landingpage,
      ...tuxConfig
    };
  }
  // If the TUX survey should NOT be shown, we return nothing
};

/**
 * Import and show the User Consent form
 */
export const showUserConsent = async ({
  hide,
  isLogin,
  locale,
  metrics,
  onSubmit,
  setSetting,
  settings,
  showToast,
  userConsentResponse,
  userConsentShown
}: ShowUserConsentOptions) => {
  const forceUserConsent = getQueryValue('forceuserconsent') === 'true';
  // Early return (to avoid unnecessary GraphQl call) if:
  // 1. Settings are not present AND the query param is NOT present
  // 2. The user is on the preferences page, because a user can open the preferences
  // page from the user consent modal and we don't want to show it again in that case
  if ((!forceUserConsent && !settings) || (getPathWithoutTenant(hashToPath()) === '/preferences')) {
    return;
  }
  // We pass through userConsentDismissed count from settings if it exists
  const {userConsentDismissed = 0} = settings || {};
  // If the user dismissed UserConsent with the Ask Me Later button, they
  // will have a userConsentDismissed count. Only show the dialog on login,
  // unless the user is using the force query param. We check the value of
  // userConsentShown in case the dialog was just shown (i.e. org switching
  // use case where isLogin is still true).
  if (userConsentDismissed > 0 && !isLogin && !forceUserConsent || userConsentShown) {
    return;
  }

  // The Shell init query retrieves the last update timestamp from the settings service.
  // If the user completed a Product Usage Consent after the last update,
  // we won't need to show the consent form.
  const {last_update_dts: lastUpdateDtsSettings} = settings?.userConsent || {};
  if (!forceUserConsent && lastUpdateDtsSettings && lastUpdateDtsSettings >= LAST_USER_CONSENT_UPDATE) {
    return;
  }

  // We care about their geolocation for the User Consent form
  // Ping azure front door to get it
  const azurePingResult = await checkCDN();

  const isUnitedStatesUser = azurePingResult && azurePingResult.country === 'us';
  // If the user has not completed a Product Usage Consent form before
  // Or it's older than the last update, we need to show one
  let last_update_dts = '', permissions: OutboundConsentPermission[] = [], showConsent = false;
  try {
    ({last_update_dts = '', permissions = []} = userConsentResponse || (await getUserConsent()).getConsentPermissions);
    showConsent = !last_update_dts || (last_update_dts < LAST_USER_CONSENT_UPDATE);
  } catch (error) {
    metrics.error('Failed to retrieve user consent permissions from GQL');
  }
  // Early return if the user has already submitted recent persmissions
  // AND the query param is NOT present
  if (!showConsent && !forceUserConsent) {
    return;
  }
  return {
    hide,
    isUnitedStatesUser,
    lastUpdate: last_update_dts,
    locale,
    onSubmit,
    permissionsResponse: permissions,
    setSetting,
    showToast,
    userConsentDismissed
  };
};

const ROLES_CONFIG = [
  {appId: 'analytics', featureFlag: 'analytics-roles-dialog-enabled', regex: /\/workspace(\/projects)?(\/folder\/\w+)?$/},
  {appId: 'experiencePlatformUI-home', featureFlag: 'enable-roles-modal', regex: /\/platform\/home\/?$/},
  {appId: 'platformAnalytics', featureFlag: 'cja-roles-dialog-enabled', regex: /\/workspace(\/projects)?(\/folder\/\w+)?$/}
];

const shouldShowByApp = (appId: string, featureFlags?: Record<string, FlagValue>) => ROLES_CONFIG.some(({appId: id, featureFlag, regex}) =>
  id === appId && featureFlags?.[featureFlag] === 'true' && regex.test(window.location.hash)
);

export const showRolesModalInvite = async ({
  featureFlags,
  metrics,
  setSetting,
  settings,
  showRolesInvite,
  solutionConfig
}: ShowRolesModalInviteOptions) => {
  if (!settings || !solutionConfig?.appId) {
    // return early if the required data hasn't loaded yet
    return;
  }
  const forceShow = getQueryValue('forcerolesmodal') === 'true';

  if (!forceShow && !(shouldShowByApp(solutionConfig.appId, featureFlags))) {
    // Return early if the feature isn't enabled or we're in the wrong app
    return;
  }

  const {userRoles = {}, userRolesTimeline = {}} = settings;
  const {count = 0, latestTimestamp} = userRolesTimeline;
  const timeSinceToast = latestTimestamp ? new Date().getTime() - new Date(latestTimestamp).getTime() : 0;
  if (!forceShow && (userRoles.roles || count > 2 || timeSinceToast && timeSinceToast < TWENTY_FOUR_HOURS)) {
    // Return early if one of the following:
    // 1. user's roles data has already been recorded
    // 2. we've shown the toast in less than 24 hours
    // 3. shown toast 3 times already
    return;
  }

  await wait(2000);
  setSetting({userRolesTimeline: {count: count + 1, latestTimestamp: new Date().toISOString()}});
  metrics.analytics.trackEvent({
    action: 'display',
    element: 'toast: tell us about yourself',
    feature: 'roles pilot',
    type: 'invite',
    widget: {name: 'roles pilot invite', type: 'info get started invite'}
  });
  showRolesInvite && showRolesInvite();
};

export const handleGainsightRoles = async (settings: UnifiedShellSettings, submittedRoles: StoredUserRoles) => {
  const {userRoles: settingsRoles} = settings;
  const userRoles = submittedRoles || settingsRoles;

  if (!userRoles?.roles) {
    return;
  }
  // submittedRoles get priority because it means the roles have updated
  // userRoles coming from state mean this is on load/context for the solution
  const {objectives = [], other = '', roles} = userRoles;
  const gainsightRoles: UserRoles = {
    roleJobFunction: {},
    rolePlatformObjective: {}
  };
  Object.values(ROLE_NAMES).forEach(r => gainsightRoles.roleJobFunction[r] = roles.includes(r));
  Object.values(OBJECTIVE_NAMES).forEach(o => {
    // If the objective is other, we include the text input string
    // Otherwise we use a boolean
    gainsightRoles.rolePlatformObjective[o] = o === 'other' ? other : objectives.includes(o);
  });

  return {gainsightRoles, userRoles};
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2023 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/*
This module encapsulates logic that is required post authentication for code splitting purposes.
 */
import 'broadcastchannel-polyfill';
import {APIProcessingService} from './services/APIProcessingService';
import {AuthMessages} from './enums';
import {AuthService, getTokenKey, RefreshEmitPayload, reload} from '@exc/auth';
import connectionMonitorService from './services/ConnectionMonitorService';
import type {CoreComponent, CoreServices} from './models/Core';
import dataPrefetchService from './services/DataPrefetchService';
import type {DiscoveryResponse, DiscoverySource} from './models/Discovery';
import {DiscoveryService} from './services/DiscoveryService';
import {displayTuxSurvey, handleGainsightRoles, showRolesModalInvite, showUserConsent} from './modules/ShellModals';
import eventObserver from './modules/EventObserver';
import FloodgateService from './services/FeatureFlagServices/FloodgateService';
import {getAccountCluster} from '@exc/graphql/src/queries/auth';
import {getBestSupportedLocale, SUPPORTED_LOCALES} from './utils/locale';
import {getBootstrap} from './services/BootstrapService';
import {getCapabilityWithIconUrl} from './services/CapabilityConstants/capabilityIcons';
import {getPerformanceMarks} from './utils/performance';
import {hashToPath, removeQueryParamsFromPath} from '@exc/url';
import {HISTORY, SolutionRoute} from './models/Solution';
import {ImsProfile} from '@adobe/exc-app/ims/ImsProfile';
import {Internal} from '@adobe/exc-app/internal';
import LaunchDarklyService from './services/FeatureFlagServices/LaunchDarklyService';
import type {Metrics} from '@adobe/exc-app/metrics';
import permissionService from './services/PermissionService';
import {PROVIDERS} from '@adobe/exc-app/featureflags';
import RecentsService from './services/RecentsService';
import {setAccessOverrides} from './modules/DebugModules/AccessOverrides';
import supportedBrowsers from './utils/supportedBrowsers';
import {updatePath} from '@exc/url/pathUpdate';
import {UserInputService} from './services/UserInputService';
import {wait} from '@exc/shared';

export interface AuthBroadcastMessage {
  data: {
    message: AuthMessages;
    sessionId?: string;
  }
}

const isBrowserSupported = () => supportedBrowsers.test(window.navigator.userAgent);

const showInitialToasts = ({getComponentContext, metrics}: CoreComponent) => {
  const url = new URL(`${window.location.origin}${hashToPath()}`);
  const redirectToast = url.searchParams.get('redirectToast');
  if (redirectToast) {
    getComponentContext?.().showToast(redirectToast);
    const path = removeQueryParamsFromPath(hashToPath(), ['redirectToast']);
    updatePath({path}, true);
  }

  // Check if the current browser is supported and show a message if it's not.
  if (!isBrowserSupported()) {
    metrics.warn('This browser is not supported.');
    getComponentContext?.().showToast('browserlistWarning');
  }
};

/**
 * Get the org of the current instance via AEM discovery endpoint using the
 * discovery service. Since this is also used to generate the session, this
 * is the only time it is needed to run on page load. Future calls will pull
 * from discovery cache.
 * @returns AEM discovery promise.
 */
const getAEMOrg = async (
  solutions: SolutionRoute[],
  environment: string
): Promise<DiscoveryResponse | undefined> => {
  const aemConfig = solutions.find(({appId}) => appId === 'aemshell');
  if (aemConfig) {
    const {
      appId,
      sandbox: {sources},
      sections,
      serviceCode,
      session: sessionConfig
    } = aemConfig;
    const source = sources[environment] as DiscoverySource;
    return new DiscoveryService().getSourceFromDiscovery(
      {...source, discovery: `${window.location.origin}${source.discovery}`},
      {imsOrg: '', imsProfile: {userId: ''}} as any,
      {
        appEnv: environment,
        appId,
        basePath: '/aem',
        history: HISTORY.SERVER,
        sections,
        serviceCode,
        sessionConfig
      }
    );
  }
};

const onAuthBroadcast = async (
  {data}: AuthBroadcastMessage,
  auth: AuthService,
  showSpinner: () => Promise<void>,
  metrics: Metrics
) => {
  const sessionId = getBootstrap().getSid();

  switch (data.message) {
    case AuthMessages.LOGOUT:
      showSpinner();
      metrics.event('AuthBroadcast.signOut');
      // Moves Main component to a wait state in order to remove sandbox.
      // This will prevent an active iframe from blocking navigation.
      await Internal.flush();
      auth.signOut();
      break;
    case AuthMessages.LOGIN:
      // If session has changed refresh browser.
      if (sessionId && sessionId !== data.sessionId) {
        metrics.event('Auth.sessionReplaced', {
          newSession: data.sessionId,
          oldSession: sessionId
        });
        await Promise.all([
          Internal.flush(),
          // Moves Main component to a wait state in order to remove sandbox.
          // This will prevent an active iframe from blocking navigation.
          showSpinner(),
          // On refresh IMSlib will see that the token is invalid and terminate the session
          // To prevent this, get a new token (Against new session) before refreshing the page
          // IMSlib stores it in session storage, so after reload it will be used by Shell.
          auth.refreshToken()]);
        reload({
          initiator: 'CoreLogin',
          reason: 'Login Broadcast'
        });
      }
      break;
    default:
      data.message && metrics.warn(`Unknown auth message: ${data.message}`);
  }
};

const getBroadcastChannel = (showSpinner: () => Promise<void>, core: CoreComponent): BroadcastChannel => {
  const authChannel = new BroadcastChannel('auth');
  core.authPromise.then(auth => authChannel.addEventListener(
    'message',
    async event => onAuthBroadcast(event, auth, showSpinner, core.metrics)
  ));
  return authChannel;
};

const addAuthListeners = (core: CoreComponent) => {
  // Listen for token updates on the auth instance and update the state with
  // the new profile and token data.
  core.authPromise.then(auth => {
    auth.on('auth.token.change', async ({clientId, isExcClient, profile, scope, token, userId}: RefreshEmitPayload) => {
      if (isExcClient) {
        // Updates the token in exc-app/network.
        core.configureNetwork(null, true);
        core.setState({accessToken: token, profile});
      } else {
        // Stores the new token information for the next cache pull.
        const key = getTokenKey(clientId, scope, userId);
        auth.customTokenService?.updateCachedTokens(key, {profile, token});
        core.onCustomTokenChange(profile, token);
      }
    });
    auth.on('auth.rideError', core.showRideError);
  });
};

const getFlagService = (provider: PROVIDERS) => provider === PROVIDERS.LAUNCH_DARKLY ? LaunchDarklyService : FloodgateService;

const setupServices = (core: CoreComponent) => {
  const services: CoreServices = {
    dataPrefetchService,
    discoveryService: new DiscoveryService(),
    getFlagService,
    permissionService,
    recentsService: new RecentsService(dataPrefetchService, core.updateLastRecent)
  };

  // Add listeners to render the ShellNetworkError component as needed
  connectionMonitorService.on('connected', () => core.setState({showNetworkDisconnected: null}));
  connectionMonitorService.on('offline', () => core.setState({showNetworkDisconnected: 'offline'}));
  connectionMonitorService.on('unstable', () => core.setState({showNetworkDisconnected: 'unstable'}));

  core.nonSupportedBrowser = !isBrowserSupported();
  core.services = services;
  addAuthListeners(core);

  core.metrics.event('PostAuth.loaded');
};

const updateProfileOnLanguageChange = async (core: CoreComponent, locale: string, profile: ImsProfile, firstCall = true) => {
  try {
    const {imsExtendedAccountClusterData: cluster} = await getAccountCluster({});
    if (cluster?.preferredLanguages) {
      if (getBestSupportedLocale([locale], SUPPORTED_LOCALES) !== getBestSupportedLocale(cluster.preferredLanguages, SUPPORTED_LOCALES)) {
        firstCall ?
          wait(15000).then(() => updateProfileOnLanguageChange(core, locale, profile, false)) :
          core.metrics.warn('Account cluster locale is incorrect', {
            accountClusterLocale: cluster.preferredLanguages[0],
            currentLocale: locale
          });
        return;
      }
      const newProfile = {...profile, preferred_languages: cluster.preferredLanguages};
      core.authPromise.then(auth => auth.updateProfile(newProfile));
      core.setState({profile: newProfile});
    }
  } catch (err) {
    core.metrics.error('Error fetching new account cluster', err);
  }
};

export {
  APIProcessingService,
  dataPrefetchService,
  displayTuxSurvey,
  eventObserver,
  getAEMOrg,
  getBroadcastChannel,
  getCapabilityWithIconUrl,
  getFlagService,
  getPerformanceMarks,
  handleGainsightRoles,
  setupServices,
  setAccessOverrides,
  showInitialToasts,
  showRolesModalInvite,
  showUserConsent,
  updateProfileOnLanguageChange,
  UserInputService
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

// eslint-disable-next-line max-len
export const HEAD_SCRIPT = `<script>!function(t,a,e,i){const{history:o,location:s,parent:n}=t,c="about:srcdoc";if(e&&(t.digitalData=t.digitalData||{},t.digitalData.nonce=e),n["exc-module-runtime"]&&n["exc-module-runtime"].bootstrapRuntime){const a=(a,e)=>t.parent.postMessage(a,e);n["exc-module-runtime"].bootstrapRuntime(t,a,i)}const r=t=>{var a="_"+t;o[a]=o[t],o[t]=(t,e,i)=>{if(i){const[n,r]=(t=>t.startsWith("#")?[t,c+t]:t.startsWith(c+"#")?[t.replace(c,""),t]:[])(i);if(n)try{o[a](t,e,r)}catch{s.hash=n}}}};r("replaceState"),r("pushState"),a&&!s.hash&&(s.hash=a)}(window,__HASH__,__NONCE__,__APPID__);</script>`;

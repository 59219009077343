/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* eslint-disable */
export default /((CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS)[ +]+(15[_\.]6|15[_\.]([7-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})[_\.]\d+|16[_\.]0|16[_\.]([1-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})[_\.]\d+)(?:[_\.]\d+)?)|(OperaMini(?:\/att)?\/?(\d+)?(?:\.\d+)?(?:\.\d+)?)|(Opera\/.+Opera Mobi.+Version\/(73\.0|73\.([1-9]|\d{2,})|(7[4-9]|[8-9]\d|\d{3,})\.\d+))|(Opera\/(73\.0|73\.([1-9]|\d{2,})|(7[4-9]|[8-9]\d|\d{3,})\.\d+).+Opera Mobi)|(Opera Mobi.+Opera(?:\/|\s+)(73\.0|73\.([1-9]|\d{2,})|(7[4-9]|[8-9]\d|\d{3,})\.\d+))|(SamsungBrowser\/(20\.0|20\.([1-9]|\d{2,})|(2[1-9]|[3-9]\d|\d{3,})\.\d+))|(Edge\/(111(?:\.0)?|111(?:\.([1-9]|\d{2,}))?|(11[2-9]|1[2-9]\d|[2-9]\d\d|\d{4,})(?:\.\d+)?))|(HeadlessChrome((?:\/109\.0\.\d+)?|(?:\/109\.([1-9]|\d{2,})\.\d+)?|(?:\/(11\d|[2-9]\d\d|\d{4,})\.\d+\.\d+)?|(?:\/111\.0\.\d+)?|(?:\/111\.([1-9]|\d{2,})\.\d+)?|(?:\/(11[2-9]|1[2-9]\d|[2-9]\d\d|\d{4,})\.\d+\.\d+)?))|((Chromium|Chrome)\/(109\.0|109\.([1-9]|\d{2,})|(11\d|[2-9]\d\d|\d{4,})\.\d+|111\.0|111\.([1-9]|\d{2,})|(11[2-9]|1[2-9]\d|[2-9]\d\d|\d{4,})\.\d+)(?:\.\d+)?)|(Version\/(15\.6|15\.([7-9]|\d{2,})|(1[6-9]|[2-9]\d|\d{3,})\.\d+|16\.3|16\.([4-9]|\d{2,})|(1[7-9]|[2-9]\d|\d{3,})\.\d+)(?:\.\d+)?.*Safari\/)|(Firefox\/(102\.0|102\.([1-9]|\d{2,})|(10[3-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+|111\.0|111\.([1-9]|\d{2,})|(11[2-9]|1[2-9]\d|[2-9]\d\d|\d{4,})\.\d+)\.\d+)|(Firefox\/(102\.0|102\.([1-9]|\d{2,})|(10[3-9]|1[1-9]\d|[2-9]\d\d|\d{4,})\.\d+|111\.0|111\.([1-9]|\d{2,})|(11[2-9]|1[2-9]\d|[2-9]\d\d|\d{4,})\.\d+)(pre|[ab]\d+[a-z]*)?)/;

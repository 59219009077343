/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getQueryParams} from '@exc/url/query';
import {HEAD_SCRIPT} from './headScript.min';
import Metrics, {Level} from '@adobe/exc-app/metrics';
import type {SrcDocOptions} from '@adobe/exc-app/modules';

export interface HTMLOptions extends SrcDocOptions {
  appId: string;
  liveVersion: string;
}

export default class AppHtmlService {
  private readonly cache: Record<string, string> = {};
  private readonly cdn: string;
  private readonly fallbackDomains: string[];
  private readonly metrics = Metrics.create('exc.core.AppHtmlService');

  constructor(cdn: string) {
    this.cdn = cdn;
    this.fallbackDomains = [window.origin];
  }

  /**
   * Injects additional code to the HTML head to allow the SPA to run in about:srcdoc.
   * @param html - Fetched SPA HTML
   * @param options - HTML request information
   * @private
   */
  private injectAdditionalCode(html: string, options: HTMLOptions): string {
    const w = window as (Window & {digitalData?: {nonce?: string}});
    const runtimeScript = document.getElementById('runtimeScript');
    const {
      appId,
      hash = '',
      nonce = runtimeScript?.nonce,
      omegaNonce = options.nonce || w.digitalData?.nonce || ''
    } = options;
    let htmlOut = html;

    const postHead = HEAD_SCRIPT
      .replace('__APPID__', `"${appId}"`)
      .replace('__HASH__', `"${hash}"`)
      .replace('__NONCE__', `"${omegaNonce}"`);

    if (htmlOut.includes('Needs to be within an iframe!");') && !htmlOut.includes(`["exc-module-runtime"]?.Runtime`)) {
      // Override runtime loader script.
      // This can be removed later when all apps on Thunderbird use the latest loader.
      htmlOut = htmlOut.replace(
        'Needs to be within an iframe!");',
        'Needs to be within an iframe!");if(window["exc-module-runtime"] && window["exc-module-runtime"].Runtime)return;');
    }

    htmlOut = htmlOut
      .replace('<HEAD>', '<head>')
      .replace('</HEAD>', '</head>')
      .replace(/(<head><script>[ \n]*window\._srp.*?<\/script>|<head>)/s, `$1${postHead}`);

    if (nonce) {
      const nonceAttr = `nonce="${nonce}"`;
      htmlOut = htmlOut
        .replace(/<(script|link)([^>]*)>/g, `<$1$2 ${nonceAttr}>`);
    }

    return htmlOut;
  }

  /**
   * Fetch SPA HTML from the network / service worker.
   * @param url - HTML url
   * @param name - URL name (For logging purposes)
   * @returns HTML
   * @private
   */
  private async fetchHtmlHelper(url: string, name: string): Promise<string> {
    let html = '';
    try {
      // Download html.
      const response = await fetch(url);
      html = await response.text();
    } catch (error) {
      this.metrics.event(`AppHtmlService.${name}.Failure`, {url}, {level: Level.ERROR});
    }
    return html;
  }

  /**
   * Fetch SPA HTML from the network / service worker.
   * @param path - Path to SPA HTML
   * @returns HTML
   * @private
   */
  private async fetchHtml(path: string): Promise<string> {
    const url = `${this.cdn}/${path}`;

    let html = await this.fetchHtmlHelper(url, 'CDN');

    if (!html) {
      // Try fallback domains.
      let fallBackIndex = 1;
      for (const domain of this.fallbackDomains) {
        html = await this.fetchHtmlHelper(`${domain}/${path}`, `Fallback${fallBackIndex}`);
        if (html) {
          break;
        }
        fallBackIndex++;
      }
    }

    return html;
  }

  /**
   * Returns SPA HTML from cache or network
   * @param options - HTML request information
   * @returns HTML
   */
  public async getHtml(options: HTMLOptions): Promise<string> {
    const queryParams = getQueryParams();
    const {hostname} = location;
    const {
      htmlPath = 'index.html',
      liveVersion,
      spaName,
      version = queryParams[`${spaName}_version`] || liveVersion
    } = options;
    const path = `solutions/${spaName}/spa-html-preload/${htmlPath}?${spaName}_version=${version}&shell_domain=${hostname}`;

    if (!this.cache[path]) {
      this.cache[path] = await this.fetchHtml(path);
    }

    return this.cache[path] ? this.injectAdditionalCode(this.cache[path], options) : '';
  }
}

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {CoreContext} from '../../models/Context';
import type {FeatureFlagConfig} from '@adobe/exc-app/featureflags';
import type {
  FeatureFlagsById,
  GetFlagsContext,
  GraphQLPayload
} from '../../models/FeatureFlags';
import FeatureFlagService from './FeatureFlagService';
import {
  FORCIBLE_PROJECTS,
  LD_GRAPHQL_QUERY_DATA_KEY,
  ORG_SANDBOX_PROJECTS,
  VALID_LAUNCH_DARKLY_PROJECTS
} from '../../utils/launchDarkly';
import {generateUserHash} from '../../utils';
import {getMultiProjectFeatureFlags} from '@exc/graphql/src/queries/launchDarkly';
import type {
  LaunchDarklyFeatureFlagsResponse,
  LaunchDarklyInput,
  ProjectFeatureFlags
} from '@exc/graphql/src/models/launchDarkly';
import {PROVIDERS} from '@adobe/exc-app/featureflags';
import {sortAndFilterFlags} from './utils';

/**
 * Feature flag service for LaunchDarkly provider
 */
class LaunchDarklyService extends FeatureFlagService {
  constructor() {
    super(
      PROVIDERS.LAUNCH_DARKLY,
      LD_GRAPHQL_QUERY_DATA_KEY as keyof LaunchDarklyFeatureFlagsResponse,
      VALID_LAUNCH_DARKLY_PROJECTS
    );
  }

  /**
   * Cleans up the array of projects' features flags.
   * @param data - GraphQL feature flag response of flag data
   * @returns A flattened object of projects with feature flag properties
   */
  flattenFeatureFlags<MultiProjectFeatureFlagResponse>(
    data: MultiProjectFeatureFlagResponse & {projects: ProjectFeatureFlags[]}
  ): FeatureFlagsById {
    // We can't flatten the response data until we get it from inside
    // the keyed response graphQL returns
    // In this case this.queryDataKey is `multiProjectFeatureFlags`
    data = data?.[this.queryDataKey];
    const {projects = []} = data;
    const flattenedFlags: FeatureFlagsById = {};
    // Go through each project's array of feature flags and set as objects
    projects.forEach(({projectId, featureFlags}: ProjectFeatureFlags) => {
      flattenedFlags[projectId] = Object.fromEntries(featureFlags.map(({name, value}) => [name, value]));
    });
    // Returned a flattened object
    return flattenedFlags;
  }

  /**
   * Checks context data to see if the required data is missing.
   * @param projectIds - LaunchDarkly project ids strings
   * @param context - Core Context
   * @returns False if context has all data needed, true otherwise.
   */
  public isContextMissing(projectIds: string[], context: CoreContext): boolean {
    const missingContext = this.isCoreContextMissing(context);
    if (missingContext) {
      return missingContext;
    }
    const {sandbox} = context;
    // Some LaunchDarkly requests require a sandbox name string
    const sandboxRequired = projectIds.some(project => ORG_SANDBOX_PROJECTS.includes(project));
    return sandboxRequired && !sandbox;
  }

  /**
   * Formats the options payload for the GraphQL request.
   * @param projectIds - LaunchDarkly project ids strings
   * @param context - Core Context
   * @returns An object of project ids with context data
   * @public
   */
  public getProviderPayload(
    projectIds: string[],
    context: GetFlagsContext
  ): LaunchDarklyInput {
    const {
      imsOrg: imsOrgId,
      imsInfo: {imsProfile},
      internal,
      locale,
      sandbox
    } = context;
    // Construct the graphql query options
    return {projectIds, userData: {custom: {
      imsOrgId,
      internal,
      locale,
      sandboxName: sandbox?.name,
      sandboxRegion: sandbox?.region,
      userHash: imsProfile?.email && generateUserHash(imsProfile.email)
    }}};
  }

  /**
   * A wrapping function to support the bulk API with a list of project ids
   * as well as clean up and sort the returned object
   * @param projectIds - Array of strings of project IDs or ['*'] for all
   * @param context - Current context object that includes options we need
   * @returns An object of project ids with flattened feature flags
   */
  async getFeatureFlags(
    projectIds: string[],
    context: GetFlagsContext,
    config?: FeatureFlagConfig
  ): Promise<FeatureFlagsById | undefined> {
    // Validate the project ids and if some are invalid
    // the base class throws an error just like before
    projectIds = this.validateProjectIds(projectIds);

    // If there are no project ids, return undefined
    if (!projectIds?.length) {
      return undefined;
    }
    // Get the feature flags
    return this.getFlagData(
      getMultiProjectFeatureFlags as (payload: GraphQLPayload) => Promise<LaunchDarklyFeatureFlagsResponse>,
      projectIds,
      context,
      FORCIBLE_PROJECTS,
      config
    ).then(flagData => sortAndFilterFlags(projectIds, flagData));
  }
}

export default new LaunchDarklyService();

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import capabilities from './capabilities';
import CapabilityIcons from 'url:@exc/brand-icons/CapabilityIcons.svg';

const CAPABILITY_ICONS = capabilities.map(capability => capability.icon);

export const getCapabilityIcon = icon => CAPABILITY_ICONS.includes(icon) ? `${CapabilityIcons}#${icon}` : '';

export const getCapabilityWithIconUrl = capability => {
  const capabilityWithIconUrl = capabilities.filter(({id, displayName}) => capability === id || capability === displayName)[0];
  if (!capabilityWithIconUrl) {
    return;
  }
  return {
    capabilityId: capabilityWithIconUrl.id,
    iconUrl: getCapabilityIcon(capabilityWithIconUrl.icon)
  };
};

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AppSandbox, OutgoingMessage} from '../models/AppSandbox';
import eventObserver, {ObserverInputEvent} from '../modules/EventObserver';
import {Events} from '@exc/shared';
import {MessageService} from './MessageService';

export class UserInputService {
  private readonly sendMessage: <T>(message: OutgoingMessage<T>) => void;
  private customFeedbackOn = false;
  private readonly secondary: boolean;

  constructor(messageService: MessageService, app: AppSandbox) {
    this.secondary = app.secondary || false;
    this.sendMessage = message => messageService.sendMessage(message, app);
    // Reset skip menu when new sandbox is constructed unless its secondary (i.e. SandboxInner)
    if (!this.secondary) {
      document.dispatchEvent(new CustomEvent('skipMenuList', {detail: null}));
      document.addEventListener('aiMessageOutgoing', this.onAiMessage);
      document.addEventListener('getSkipMenu', this.fetchSkipMenu);
      document.addEventListener('onLeftNavClick', this.onLeftNavClick);
    }
    eventObserver.on('keyboard', this.handleUserInputEvent);
    eventObserver.on('click', this.handleUserInputEvent);
    eventObserver.on('mouseup', this.handleUserInputEvent);
  }

  public set customFeedback(feedback: boolean) {
    this.customFeedbackOn = feedback;
  }

  /**
   * This functon is handler for all events happening on the shell.
   * @param {Object} value The payload.
   */
  public handleUserInputEvent = (value: ObserverInputEvent) => {
    // The target object is too large to include in the post message
    const {target, ...slimValue} = value;
    // Handle a11y skip menu actions - Except for secondary frames (i.e. SandboxInner)
    this.secondary || this.handleSkipMenuEvent(target as Element, slimValue);
    // Handle customFeedback clicks
    if (this.customFeedbackOn && value.type === 'click' && value.data?.className?.includes('spectrum-Shell-feedback-button')) {
      this.sendMessage({type: Events.CUSTOM_FEEDBACK, value: slimValue});
    }
    this.sendMessage({type: Events.USER_INPUT, value: slimValue});
  };

  /**
   * This functon handles all a11y skip menu events happening on the shell.
   * @param {Object} target The event target.
   * @param {Object} keyCode The event keyCode from the payload value.
   * @param {Object} type The event type from the payload value.
   */
  private handleSkipMenuEvent = (target: Element, {keyCode, type}: Omit<ObserverInputEvent, 'target'>) => {
    // Ensure this is a skip menu event. We check both
    // the target and it's parent in case it's a label
    // and the values we need are on the button instead
    /* eslint-disable-next-line max-len */
    const skipAction = target.closest('.skipMenu-listItem');
    // Early return is not a skip menu action
    if (!skipAction) {
      return;
    }
    // Get the data attribute for skip menu items
    const data = skipAction.getAttribute('data-skip-menu');
    // Send focus on skip menu click, space or enter keypress
    if (type === 'click' || (keyCode === 13 || keyCode === 32)) {
      if (data === 'fallback') {
        // Fall back to our iframe if the skip menu key is a fallback
        // This also handles the Safari case where we have to use the iframe
        // because Safari prevents programatic focusing
        // (https://github.com/braintree/braintree-web/issues/490)
        const main = document.querySelector<HTMLElement>('[id^="exc-app-sandbox-"]');
        if (main) {
          main.tabIndex = -1;
          main.focus();
          main.addEventListener('blur', () => main.removeAttribute('tabIndex'), {once: true});
        }
        return;
      }
      // Otherwise post a message with the skip item key
      return this.sendMessage({type: Events.SKIP_MENU_FOCUS, value: data});
    }
    // Escape key removes focus on skip menu
    if (keyCode === 27) {
      (document.activeElement as HTMLElement).blur();
    }
  };

  private fetchSkipMenu = () => this.sendMessage({type: Events.SKIP_MENU_GET_LIST});
  private onAiMessage = (event: Event) => this.sendMessage({type: Events.AI_MESSAGE, value: (event as CustomEvent).detail});

  private onLeftNavClick = (event: Event) => this.sendMessage({
    type: Events.NAV_ITEM_CLICK,
    value: (event as any).detail
  });

  public unmount() {
    this.customFeedbackOn = false;
    eventObserver.off('keyboard', this.handleUserInputEvent);
    eventObserver.off('click', this.handleUserInputEvent);
    eventObserver.off('mouseup', this.handleUserInputEvent);
    // Secondary frames (i.e. SandboxInner) are not listening to skip menu events.
    if (!this.secondary) {
      document.removeEventListener('aiMessageOutgoing', this.onAiMessage);
      document.removeEventListener('getSkipMenu', this.fetchSkipMenu);
      document.removeEventListener('onLeftNavClick', this.onLeftNavClick);
    }
  }
}

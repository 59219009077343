/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {CustomMouseEvent, KeyCombo, throttleQueue} from '@exc/shared';
import Emitter from '@exc/emitter';
import {Internal} from '@adobe/exc-app/internal';
import {v4 as uuidv4} from 'uuid';

export type EventCallback = () => void;

interface RegisteredEvent {
  combo: KeyCombo;
  callback: EventCallback;
  id: string;
}

export interface ObserverInputEvent {
  altKey?: boolean;
  bubbles?: boolean;
  ctrlKey?: boolean;
  data?: {
    className?: string;
    textContent?: string;
  },
  keyCode?: number;
  target: EventTarget;
  type: string;
}

const ACTIVITY_EVENTS = [
  'active',
  'iframeActive',
  'keyboard'
];

/**
 * Class that listens to all events on the Shell.
 */
class EventObserver extends Emitter {
  private readonly deniedTags = ['INPUT', 'TEXTAREA']; // don't emit event when user is typing
  private readonly modifiers = ['altKey', 'ctrlKey', 'metaKey', 'shiftKey'];
  private readonly allowedClasses = ['.spectrum-Shell-feedback-button'];
  private readonly emitActivity: () => void;
  private registeredCombos: RegisteredEvent[];
  private preventDefaultCombos: KeyCombo[];

  /**
   * Initialise EventObservers and related observers.
   */
  constructor() {
    super();
    this.preventDefaultCombos = [];
    this.registeredCombos = [];
    this.emitActivity = throttleQueue(() => this.emit('active'), 1000, true);

    this.watch();
    Internal.registerActivityEmitter(this);
  }

  get activityEvents() {
    return ACTIVITY_EVENTS;
  }

  /**
   * Registers a keypress handler for a unified shell component.
   * @param combo Keyboard combination.
   * @param {Function} callback Callback to be executed on combination.
   */
  public registerKeyHandler = (combo: KeyCombo, callback: EventCallback): string | undefined => {
    if (!combo || !callback) {
      return;
    }
    const id = uuidv4();
    this.registeredCombos.push({callback, combo, id});
    return id;
  };

  public unregisterKeyHandler(idToRemove: string): void {
    const idx = this.registeredCombos.findIndex(({id}) => id === idToRemove);
    this.registeredCombos.splice(idx, 1);
  }

  /**
   * Checks if deniedTags contains the input element's tagname
   * or if the element has a 'contenteditable' attribute set to 'true'.
   * @param {Object} element The HTML DOM element.
   * @returns {boolean} Returns true if the tag is in deniedTags.
   */
  public isDeniedTag = (element: Element) => {
    const isContentEditable = element.hasAttribute('contenteditable');
    return this.deniedTags.includes(element.tagName) || isContentEditable;
  };

  /**
   * Checks if the element contains at least one class from a list of classes.
   * @param classes - CSS Classes to check
   * @param element The HTML DOM element.
   * @returns Returns className if the element contains the class.
   */
  containsClass = (classes: string[], element: Element): string | undefined => classes.find(className => element.closest(className));

  /**
   * Mouse onClick Handler.
   * @param {Event} e CustomMouseEvent.
   */
  public handleClick = (e: CustomMouseEvent) => {
    this.emitActivity();
    const targetElement = e.target as Element;

    if (!targetElement) {
      return;
    }

    const allowedClass = this.containsClass(this.allowedClasses, targetElement);
    const isTrackingRequired = !!allowedClass || !this.isDeniedTag(targetElement);

    if (!e || !isTrackingRequired) {
      return;
    }
    const {className, textContent} = targetElement;
    // Don't send through events that are not useful (possible case, SVG clicks)
    if ((!className || typeof className !== 'string') && !textContent && !e.data) {
      return;
    }
    this.emit(e.type, {
      data: {className, textContent, ...e.data || {}},
      target: e.target,
      type: e.type
    });
  };

  /**
   * Checks if the key event matches the key combination.
   * @param e Keyboard event.
   * @param combo Key combination.
   */
  private comboMatches = (e: KeyboardEvent, combo: KeyCombo) =>
    e.key === combo.key && this.modifiers.every(key => !(key in combo) || combo[key as keyof KeyCombo] === e[key as keyof KeyCombo]);

  public handleIframeActive = () => this.emit('iframeActive');

  /**
   * Key press handler for events from the iframe.
   * @param {Event} e Key event.
   */
  public handleIframeKeyPress = (e: KeyboardEvent) => {
    const element = document.activeElement;
    (element && this.isDeniedTag(element)) ||
    this.registeredCombos.some(({callback, combo}) => this.comboMatches(e, combo) && callback());
  };

  /**
   * Key press handler.
   * @param {Event} e Key event.
   */
  public handleKeyPress = (e: KeyboardEvent) => {
    const element = document.activeElement;
    if (this.modifiers.some(key => e[key as keyof KeyboardEvent])) {
      this.checkAgainstCombos(e);
    }
    // Handle shortcuts when focus is in the shell
    this.handleIframeKeyPress(e);
    if (element && this.isDeniedTag(element)) {
      this.emitActivity();
      return;
    }
    this.emit('keyboard', {
      altKey: e.altKey,
      bubbles: e.bubbles,
      ctrlKey: e.ctrlKey,
      isComposing: e.isComposing,
      key: e.key,
      keyCode: e.keyCode,
      location: e.location,
      metaKey: e.metaKey,
      repeat: e.repeat,
      shiftKey: e.shiftKey,
      target: e.target,
      type: e.type
    });
  };

  /**
   * Checks pressed key against configured list of key combinations
   * to prevent default on. Combos can be any number of modifier keys and a single
   * keycode.
   * @param {Event} e Key event.
   */
  checkAgainstCombos = (e: KeyboardEvent) => {
    for (const combo of this.preventDefaultCombos) {
      if (this.comboMatches(e, combo)) {
        e.preventDefault();
        return;
      }
    }
  };

  /**
   * Sets the array of key combination objects.
   * @param {Array} arr An array of combo objects.
   */
  public setPreventDefaultCombos = (arr: KeyCombo[]) => {
    this.preventDefaultCombos = arr;
  };

  /**
   * Add listeners for events to the window object.
   */
  watch = () => {
    window.addEventListener('keydown', this.handleKeyPress);
    window.addEventListener('click', this.handleClick);
    window.addEventListener('mouseup', this.handleClick);
    window.addEventListener('mousemove', () => this.emitActivity());
    window.addEventListener('focus', () => this.emitActivity());
    window.addEventListener('scroll', () => this.emitActivity());
  };
}

export default new EventObserver();

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2024 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  AllFeatureFlagsResponse,
  AllFloodgateFlagsResponse,
  FeatureFlagsResponse,
  FeatureMetadata,
  FloodgateFeature,
  FloodgateFeatureFlagsResponse
} from '@exc/graphql/src/models/floodgate';
import type {
  AllFlagsContext,
  FeatureFlagsById,
  GetFlagDataContext,
  GetFlagsContext,
  GraphQLPayload
} from '../../models/FeatureFlags';
import {
  CONTEXT_VARIABLES_MAP,
  FG_GRAPHQL_QUERY_ALL_DATA_KEY,
  FG_GRAPHQL_QUERY_DATA_KEY,
  ONBOARDED_CLIENT_IDS,
  SANDBOX_VARIABLES_MAP,
  VALID_CLIENT_IDS
} from '../../utils/floodgate';
import type {CoreContext} from '../../models/Context';
import {decodeBase64String} from '../../utils/decodeString';
import type {FeatureFlagConfig} from '@adobe/exc-app/featureflags';
import FeatureFlagService from './FeatureFlagService';
import {generateUserHash} from '../../utils';
import {getAllFloodgateFeatureFlags, getFloodgateFeatureFlags} from '@exc/graphql/src/queries/floodgate';
import {PROVIDERS, SANDBOX_TYPES} from '@adobe/exc-app/featureflags';
import type {Sandbox} from '../../models/Sandbox';
import {sortAndFilterFlags} from './utils';

class FloodgateService extends FeatureFlagService {
  constructor() {
    super(
      PROVIDERS.FLOODGATE,
      FG_GRAPHQL_QUERY_DATA_KEY as keyof FloodgateFeatureFlagsResponse,
      VALID_CLIENT_IDS
    );
    this.onboardedClientIds = ONBOARDED_CLIENT_IDS;
  }

  /**
   * Cleans up the floodgate response for all possible feature flags.
   * @param data - Array of feature flag objects
   * @returns A flattened object of projects with feature flag properties
   */
  flattenAllFeatureFlags(data: AllFeatureFlagsResponse[]): FeatureFlagsById {
    const flattenedFlags: FeatureFlagsById = {};
    data.forEach(({clientId, featureFlags}: AllFeatureFlagsResponse) => {
      flattenedFlags[clientId] = {};
      featureFlags.forEach(({meta, name}: FloodgateFeature) => {
        flattenedFlags[clientId][name] = meta.length > 0 ? decodeBase64String(meta) as string : 'false';
      });
    });
    return flattenedFlags;
  }

  /**
   * Cleans up the floodgate response for enabled feature flags.
   * @param data - Array of feature flag objects
   * @returns A flattened object of projects with feature flag properties
   */
  flattenEnabledFeatureFlags(data: FeatureFlagsResponse[]): FeatureFlagsById {
    const flattenedFlags: FeatureFlagsById = {};
    data.forEach(({clientId, featureFlags}: FeatureFlagsResponse) => {
      flattenedFlags[clientId] = {};
      featureFlags.forEach(({features = [], featuresMeta = []}) => {
        features.forEach(f => flattenedFlags[clientId][f] = 'true');
        featuresMeta.forEach(({feature, meta}: FeatureMetadata): void => {
          flattenedFlags[clientId][feature] = meta;
        });
      });
    });
    return flattenedFlags;
  }

  /**
   * Checks for the types of Floodgate response then invokes the corresponding
   * helper function to clean up the feature flags response.
   * @param data - Array of feature flag objects
   * @returns A flattened object of projects with feature flag properties
   */
  flattenFeatureFlags<FeatureFlagData>(data: FeatureFlagData): FeatureFlagsById {
    if (this.queryDataKey in (data as FloodgateFeatureFlagsResponse)) {
      return this.flattenEnabledFeatureFlags((data as FloodgateFeatureFlagsResponse)?.[this.queryDataKey]);
    } else if (FG_GRAPHQL_QUERY_ALL_DATA_KEY in (data as AllFloodgateFlagsResponse)) {
      return this.flattenAllFeatureFlags((data as AllFloodgateFlagsResponse)?.[FG_GRAPHQL_QUERY_ALL_DATA_KEY]);
    }
    return {};
  }

  public async getAllFeatureFlags(clientIds: string[], context: GetFlagsContext): Promise<FeatureFlagsById | undefined> {
    const {hashedAuthId} = context;
    // In the case of Floodgate, we ensure there are client ids to fetch
    clientIds = this.validateProjectIds(clientIds);
    // If there are no client ids, return undefined
    if (!clientIds?.length) {
      return undefined;
    }
    const contextOptions = {
      alternateDataKey: FG_GRAPHQL_QUERY_ALL_DATA_KEY,
      contextCacheKey: 'all',
      hashedAuthId,
      includeContext: false
    };
    // Get the feature flags
    return this.getFlagData(
      getAllFloodgateFeatureFlags as (payload: GraphQLPayload) => Promise<AllFloodgateFlagsResponse>,
      clientIds,
      contextOptions as AllFlagsContext
    ).then(flagData => sortAndFilterFlags(clientIds, flagData));
  }

  /**
   * A wrapping function to support the bulk API with a list of client ids
   * as well as clean up and sort the returned object
   * @param clientIds - Array of strings of client IDs
   * @param context - Current context object that includes options we need
   * @returns An object of client ids with flattened feature flags
   */
  public async getFeatureFlags(
    clientIds: string[],
    context: GetFlagsContext,
    config?: FeatureFlagConfig
  ): Promise<FeatureFlagsById | undefined> {
    // In the case of Floodgate, we ensure there are client ids to fetch
    clientIds = this.validateProjectIds(clientIds);
    // If there are no client ids, return undefined
    if (!clientIds?.length) {
      return undefined;
    }
    // Get the feature flags
    return this.getFlagData(
      getFloodgateFeatureFlags as (payload: GraphQLPayload) => Promise<FloodgateFeatureFlagsResponse>,
      clientIds,
      context,
      undefined,
      config
    ).then(flagData => sortAndFilterFlags(clientIds, flagData));
  }

  /**
   * Formats the options payload for the GraphQL request.
   * @param clientIds - Client ids
   * @param context - Core Context
   * @returns An object of client ids with context data
   * @public
   */
  public getProviderPayload(clientIds: string[], context: GetFlagDataContext): GraphQLPayload {
    // The GraphQL request to Floodgate for allFgFeatureFlags will error if it includes
    // context data, so we return early with just the client ids for the payload
    const {includeContext = true} = context as AllFlagsContext;
    if (!includeContext) {
      return {clientIds};
    }
    const {imsOrg, imsInfo: {imsProfile}, sandbox} = context as GetFlagsContext;
    // Construct the user context values first
    // These values must match the context keys in Floodgate
    const addCtx = [];
    for (const [key, value] of Object.entries(CONTEXT_VARIABLES_MAP)) {
      const contextValue = (context as GetFlagsContext)[key as keyof GetFlagsContext] ?? imsProfile?.[key];
      if (key === 'imsOrg') {
        addCtx.push({key: value, value: imsOrg});
      } else if (key === 'userHash') {
        // userHash is a string value we generate off the profile email
        // it is only used in rare cases for feature flag targeting
        addCtx.push({key: value, value: generateUserHash(imsProfile?.email && generateUserHash(imsProfile.email))});
      } else {
        // Internal is a boolean and can be false so we check that it is not undefined
        (contextValue || typeof contextValue === 'boolean') && addCtx.push({key: value, value: contextValue});
      }
    }
    // Add the sandbox values if they exist
    if (sandbox as Sandbox) {
      for (const [key, value] of Object.entries(SANDBOX_VARIABLES_MAP)) {
        let sandboxValue = sandbox && sandbox[key as keyof Sandbox];
        // Floodgate requires the type string to be all caps
        if (key === 'type') {
          sandboxValue = SANDBOX_TYPES[sandboxValue as keyof typeof SANDBOX_TYPES];
        }
        sandboxValue && addCtx.push({key: value, value: sandboxValue});
      }
    }
    // Return the payload
    return {
      addCtx,
      clientIds
    };
  }

  /**
   * Checks context data to see if the required data is missing.
   * @param clientIds - Client ids
   * @param context - Core Context
   * @returns False if context has all data needed, true otherwise.
   */
  isContextMissing(clientIds: string[], context: CoreContext): boolean {
    return this.isCoreContextMissing(context);
  }
}

export default new FloodgateService();

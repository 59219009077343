/*************************************************************************
 * Copyright 2021 Adobe
 * All Rights Reserved.
 *
 * NOTICE: Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying
 * it. If you have received this file from a source other than Adobe,
 * then your use, modification, or distribution of it requires the prior
 * written permission of Adobe.
 **************************************************************************/

/**
 * APIs to get, set or invalidate session data. These APIs are meant for applications using their
 * own session management in addition Adobe IMS. These APIs are not meant to manage session but
 * rather cache and recycle them whenever possible.
 *
 * To consume this API, add the following import to your code.
 *
 * ```typescript
 * import session from '@adobe/exc-app/session';
 * ```
 *
 * The default export is an object of type [SessionApi](../interfaces/_session_.sessionapi.md)
 *
 * API reference: [scroll down](#index)
 *
 * ### Sample code
 *
 * ``` typescript
 * import session from '@adobe/exc-app/settings';
 *
 * // Get
 * const session = await session.get();
 *
 * // Set to expire an hour from now
 * await session.set({
 *   expires: Date.now() + 3600 * 1000,
 *   id: 'SESSION_ID'
 * });
 *
 * // Invalidate session
 * const session = await session.get();
 * await session.invalidate(session);
 * ```
 * @packageDocumentation
 * @module session
 */
import {getImpl} from './src/Global';

/**
 * Sets the scope validity. Ensuring a session can not be used outside of its context.
 */
export enum SessionScope {
  /**
   * User - Session is valid for the current user only.
   */
  USER = 'user',
  /**
   * Organization - Session is valid for the current user and organization only.
   */
  ORG = 'org',
  /**
   * Context - Session is valid for the current user, organization and context only.
   * Context depends on the solution - Could be Sandboxes or Sub orgs.
   */
  CONTEXT = 'context'
}

/**
 * The session object.
 */
export interface Session {
  /**
   * Timestamp indicating session expiration time.
   * 0 will use default TTL configured for the application.
   */
  expires: number;
  /**
   * Session identifier
   */
  id: string;
}

/**
 * APIs to get, set or invalidate session data. These APIs are meant for applications using their
 * own session management in addition Adobe IMS. These APIs are not meant to manage session but
 * rather cache and recycle them whenever possible.
 */
export interface SessionApi {
  /**
   * Gets a stored session if one is available.
   * @returns Session object
   */
  get(): Promise<Session>;

  /**
   * Stores the current session
   * @param {object} Session object
   */
  set(session: Session): Promise<void>;

  /**
   * Invalidates a session if its current.
   * @param {object} Session object
   */
  invalidate(session: Session): Promise<void>;
}

const session = {
  get: () => getImpl('session')().get(),
  invalidate: (session: Session) => getImpl('session')().invalidate(session),
  set: (session: Session) => getImpl('session')().set(session)
} as SessionApi;

export default session;


/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {
  AccessOverridesData,
  AccessOverridesStorageObject
} from '../../../models/AccessOverrides';
import type {FFOverride, FlagValue} from '../../../models/LaunchDarkly';
import type {FulfillableItems} from '../../../models/Context';
import {
  mergeFeatureFlagsForShell,
  mergeFulfillableItemsForShell
} from './common';
import {SHELL_FLAG_PREFIX} from '../../../utils/floodgate';
import {SHELL_LD_PROJECT} from '../../../utils/launchDarkly';
import {storage} from '@exc/storage';

interface AppAssemblyService {
  setFeatureFlags: (featureflags: Record<string, FlagValue>) => void;
  setFulfillableItems: (fis: FulfillableItems) => void;
}

export interface SetAccessOverrideParameters {
  appAssemblyService?: AppAssemblyService;
  enabledState: boolean;
  hashedAuthId: string;
  featureFlags?: Record<string, string>;
  floodgateFlags?: Record<string, string>;
  fiServiceCode?: string;
  selectedImsOrg: string;
  userFulfillableItems: FulfillableItems;
}

export const parseFlagsByPrefix = (flags: Record<string, string>, prefix?: string) => {
  const validPrefixes = /^fg:/;
  const filteredFlags: Record<string, string> = {};
  for (const [key, value] of Object.entries(flags)) {
    // If there's a prefix match, add it to the filtered flags
    // Otherwise, only add it to the filtered flags if there's no prefix
    if (prefix ? key.startsWith(prefix) : !validPrefixes.test(key)) {
      filteredFlags[key] = value;
    }
  }
  return filteredFlags;
};

export const handleOverridesByProvider = (featureFlags: Record<string, string>, overrides: AccessOverridesData) => {
  // Separate the feature flags by provider to handle the overrides correctly for each
  // Core uses a prefix to differentiate Floodgate flags, so we need to handle the prefix
  // so the data is merged correctly
  const ldFlags = featureFlags && parseFlagsByPrefix(featureFlags);
  const fgFlags = featureFlags && parseFlagsByPrefix(featureFlags, SHELL_FLAG_PREFIX);
  const {featureFlags: featureFlagsOverride, floodgate: floodgateFlagsOverride} = overrides;
  // Merge LaunchDarkly flags
  const activeLDFlags = ldFlags && mergeFeatureFlagsForShell(
    featureFlagsOverride?.[SHELL_LD_PROJECT], ldFlags
  );
  const floodgateOverrides: FFOverride[] = [];
  // Floodgate access overrides are not stored with a prefix, so we need to add it
  floodgateFlagsOverride?.['unified-shell']?.forEach(({isOverride, key, value}: FFOverride) => {
    floodgateOverrides.push({isOverride, key: `${SHELL_FLAG_PREFIX}${key}`, value});
  });
  // Merge Floodgate flags
  const activeFloodgateFlags = fgFlags && mergeFeatureFlagsForShell(
    floodgateOverrides, fgFlags
  );
  return {...activeLDFlags, ...activeFloodgateFlags};
};

export const setAccessOverrides = async ({
  appAssemblyService,
  enabledState,
  featureFlags,
  fiServiceCode,
  hashedAuthId,
  selectedImsOrg,
  userFulfillableItems
}: SetAccessOverrideParameters) => {
  // Check storage for enablement and data (need for page refresh)
  const storedOverrides = await storage.local.get<AccessOverridesStorageObject>('accessOverrides');
  const userOverrides: AccessOverridesData = (storedOverrides?.[hashedAuthId] || {featureFlags: {}, floodgate: {}, fulfillableItems: {}});
  // We only implement the overrides if they are currently enabled (toggle is on)
  if (enabledState || userOverrides.enableOverrides) {
    const {fulfillableItems} = userOverrides;
    // Feature flags - Requires feature flags in state
    const activeFeatureFlags = featureFlags && handleOverridesByProvider(featureFlags, userOverrides);
    // Update AppAssembly with the active feature flags
    activeFeatureFlags && appAssemblyService?.setFeatureFlags(activeFeatureFlags);
    // Fulfillable items - Requires a fiServiceCode, indicating we're in a solution
    // that uses fulfillable items
    const activeFulfillableItems = fiServiceCode && mergeFulfillableItemsForShell(
      selectedImsOrg,
      fiServiceCode,
      userFulfillableItems,
      fulfillableItems[fiServiceCode]
    );
    // Update AppAssembly with the active fulfillable items
    activeFulfillableItems && appAssemblyService?.setFulfillableItems(activeFulfillableItems);
    return {
      accessOverridesEnabled: true,
      activeFeatureFlags,
      activeFulfillableItems: activeFulfillableItems || userFulfillableItems
    };
  }
};


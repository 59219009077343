/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {ConsentRequest, GetUserConsentResponse, SetUserConsentResponse} from '../models/userConsent';
import {gqlQuery} from '../index';

export const USER_CONSENT_QUERY = `
  query getConsentPermissions {
    getConsentPermissions {
      last_update_dts
      permissions {
        name
        enabled
        last_update_dts
      }
    }
  }
`;

export const SET_USER_CONSENT = `
  mutation setConsentPermissions($source: String!, $permissions: [ConsentPermissionInput!]!) {
    setConsentPermissions(source: $source, permissions: $permissions) {
      success
      errors
    }
  }
`;

export const getUserConsent = (): Promise<GetUserConsentResponse> =>
  gqlQuery<GetUserConsentResponse, undefined>(
    USER_CONSENT_QUERY,
    undefined
  );

export const setUserConsent = (consentRequest: ConsentRequest): Promise<SetUserConsentResponse> =>
  gqlQuery<SetUserConsentResponse, ConsentRequest>(
    SET_USER_CONSENT,
    consentRequest
  );


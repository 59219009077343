/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import {getUserPermissions} from '@exc/graphql/src/queries/palm';
import metrics from '@adobe/exc-app/metrics';
import type {Parameters, Permissions, PermissionsResponse} from '@adobe/exc-app/permissions';
import {Sandbox} from '@adobe/exc-app/user';

/**
 * Map of key property to high level permission prefix.
 */
const KEY_MAP = {
  permissions: 'permissions',
  resourceTypes: 'resource-types'
};

interface PermissionPromise {
  permissions: Parameters;
  promise: Promise<PermissionsResponse<Permissions>>;
  reject: () => void;
  resolve: (response: PermissionsResponse<Permissions>) => void;
}

/**
 * Generate the org/sandbox key that will be used to manage the data.
 * @param orgId Org portion of the key.
 * @param name Sandbox name.
 * @param type Sandbox type.
 * @returns Unique key for storing permissions.
 */
export const getKey = (orgId: string, {name, type}: Sandbox) => `${orgId}||${type}:${name}`;

/**
 * Permission service.
 */
export class PermissionService {
  private readonly metrics = metrics.create('exc.PermissionService');
  /**
   * Set of keys that have been fetched this session.
   */
  private readonly fetched = new Set<string>();

  /**
   * Max number of key/permission pairs to store.
   */
  private readonly maxKeys = 10;

  /**
   * List of the last n (up to this.maxKeys) keys that exist in the cache. When
   * a new one is added the 0th item is removed.
   */
  private readonly permissionKeys: string[] = [];

  /**
   * Map of key to permissions object.
   */
  private permissions: Record<string, Permissions> = {};

  /**
   * Map of key to promises for in-progress fetches.
   */
  private promises: Record<string, Record<string, PermissionPromise>> = {};

  /**
   * Builds the permission payload that will be sent to the resolved promise.
   * @param requestedPermissions Object containing lists of permissions
   *   and resource types to build the payload from.
   * @param key The current org/sandbox key for permissions.
   * @returns Permissions Response
   */
  buildPayload(requestedPermissions: Parameters, key: string): PermissionsResponse<Permissions> {
    const res: PermissionsResponse<Permissions> = {};
    const storedPermissions = (this.permissions || {})[key];
    storedPermissions && (Object.keys(requestedPermissions) as (keyof Parameters)[]).forEach(type => {
      (requestedPermissions[type] || []).forEach(reqPerm => {
        if (reqPerm === '*') {
          Object.entries(storedPermissions).forEach(([storedKey, storedVal]) => {
            const permType = `/${KEY_MAP[type]}/`;
            if (storedKey.startsWith(permType)) {
              reqPerm = storedKey.replace(permType, '');
              res[type] = res[type] || {};
              (res[type] as Record<string, string[]>)[reqPerm] = storedVal;
            }
          });
        }
        const permKey = `/${KEY_MAP[type]}/${reqPerm}`;
        if (storedPermissions[permKey]) {
          res[type] = res[type] || {};
          (res[type] as Record<string, string[]>)[reqPerm] = storedPermissions[permKey];
        }
      });
    });
    return res;
  }

  /**
   * Attempt to complete unresolved promises that are waiting for certain
   * permissions to be available.
   * @param key The current org/sandbox key for permissions.
   */
  completePromises(key: string) {
    if (!(key in this.promises)) {
      return;
    }

    Object.values(this.promises[key]).forEach(({permissions, resolve}) =>
      resolve(this.buildPayload(permissions, key)));
    delete this.promises[key];
  }

  /**
   * Ensures that the list of cached permissions remains at `maxKeys` length.
   * Removes the key from the list if it exists and adds it back to the top of
   * the list to show that it's been used most frequently. It also removes the
   * oldest key from the list if the list has grown too large.
   * @param key Org ID / sandbox key.
   */
  ensureCacheSize(key: string) {
    // Remove the current key, as it'll be added back to the top later.
    const keyIdx = this.permissionKeys.indexOf(key);
    keyIdx > -1 && this.permissionKeys.splice(keyIdx, 1);

    // Ensure that the list of permissions doesn't get too large.
    this.permissionKeys.splice(0, 0, key);
    if (this.permissionKeys.length > this.maxKeys) {
      const removedKey = this.permissionKeys.pop() as string;
      delete this.permissions[removedKey];
    }
  }

  /**
   * Fetches user permissions from PALM via GQL.
   * @async
   * @param orgId Current Org ID.
   * @param sandbox PALM Sandbox.
   */
  async fetch(orgId: string, sandbox: Sandbox) {
    const key = getKey(orgId, sandbox);

    try {
      const res = await getUserPermissions();
      const policies = res.effectivePolicies.policies;
      const permissions: Permissions = {};
      policies.forEach(({name, privileges}) => permissions[name] = privileges);
      this.set(orgId, sandbox, permissions);
      this.fetched.add(key);
    } catch (e) {
      this.metrics.error(`Failed to fetch user permissions: ${e}`, {orgId, sandbox});
      this.rejectPromises(key);
    }
  }

  /**
   * Gets a permissions object containing requested permissions. This is either
   * a resolved promise if the data exists or a promise that will be resolved
   * once the permissions are available.
   * @param permissions Object containing permissions and resourceTypes
   *   to get for the current key.
   * @param orgId Current Org ID.
   * @param sandbox PALM Sandbox.
   * @returns Permissions Response
   */
  public get(permissions: Parameters, orgId: string, sandbox: Sandbox): Promise<PermissionsResponse<Permissions>> {
    const key = getKey(orgId, sandbox);
    // If there is an existing promise for the requested key, return the promise.
    if (key in this.promises) {
      return this.getPromise(permissions, key);
    }
    // If the data hasn't been fetched this session yet, fetch it. Always gets
    // data once per session to ensure the user has up-to-date data every couple
    // of refreshes so a log-out isn't required.
    this.fetched.has(key) || this.fetch(orgId, sandbox);
    // If the key exists in permissions, there is data, so resolve the promise
    // with that data. If not, return the promise for the data.
    return key in this.permissions ?
      Promise.resolve(this.buildPayload(permissions, key)) :
      this.getPromise(permissions, key);
  }

  /**
   * Creates a promise and adds it to the list of unresolved promises.
   * @param permissions Permissions/resourceTypes needed to resolve the
   *   new promise.
   * @param key The current org/sandbox key for permissions.
   * @returns Permissions Promise
   */
  getPromise(permissions: Parameters, key: string): Promise<PermissionsResponse<Permissions>> {
    const jsonString = JSON.stringify(permissions);
    // Checks if there is a promise for the current permissions requested. If
    // so, return that promise to the caller.
    if (key in this.promises && jsonString in this.promises[key]) {
      return this.promises[key][jsonString].promise;
    }
    const res = {permissions} as PermissionPromise;
    res.promise = new Promise((resolve, reject) => {
      res.resolve = resolve;
      res.reject = reject;
    });
    this.promises[key] = this.promises[key] || {};
    this.promises[key][jsonString] = res;
    return res.promise;
  }

  /**
   * Rejects unresolved promises that are waiting for certain permissions to be
   * available if an error occurs when fetching.
   * @param key The current org/sandbox key for permissions.
   */
  rejectPromises(key: string) {
    if (!(key in this.promises)) {
      return;
    }
    Object.values(this.promises[key]).forEach(({reject}) => reject());
    delete this.promises[key];
  }

  /**
   * Set the permissions that this service maintains. This will come from GQL.
   * Attempt to complete existing promises with the newly received promises.
   * @param orgId The org to assign to the
   * @param sandbox The current sandbox to use for permissions requests.
   * @param permissions Permissions object to use.
   */
  set(orgId: string, sandbox: Sandbox, permissions: Permissions) {
    const key = getKey(orgId, sandbox);
    this.permissions[key] = permissions;
    // Ensures the cached set of permissions is up-to-date, has removed the
    // oldest key / permissions, and keeps the list ordered.
    this.ensureCacheSize(key);
    this.completePromises(key);
  }

  /**
   * Sets the context in which this service should operate.
   * @param orgId The current org to use for permissions requests.
   * @param sandbox The current sandbox to use for permissions requests.
   * @param hasAEP Does the org have AEP?
   */
  setContext(orgId: string, sandbox: Sandbox, hasAEP = true) {
    // We don't need to fetch permissions if the user doesn't have PALM
    if (!hasAEP) {
      return;
    }

    const key = getKey(orgId, sandbox);
    // If there are permissions for this key already, don't need to fetch again.
    // Additionally, updates the order of the caching keys to keep the latest
    // key at the top of the list.
    if (key in this.permissions) {
      this.ensureCacheSize(key);
    }
    // Fetch the data once per page load. This ensures the data will always be
    // up-to-date on across page loads.
    this.get({}, orgId, sandbox);
  }
}

export default new PermissionService();

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {CachedSession, DiscoveryOptions, DiscoveryResponse, DiscoverySource} from '../models/Discovery';
import type {CoreContext} from '../models/Context';
import type {DiscoveryService} from './DiscoveryService';
import {getLocalCache} from '@exc/storage';
import hashString from 'string-hash';
import {Session, SessionScope} from '@adobe/exc-app/session';
import type {SessionConfig} from '../models/Solution';

const STORAGE_KEY = 'unifiedShellCachedSessions';
const localCache = getLocalCache<DiscoveryResponse|CachedSession>(STORAGE_KEY);

class SessionService {
  /**
   * Get session from response when available.
   * @param {DiscoveryResponse} response - Discovery response data
   * @param {DiscoverySource} source - Solution discovery source definition
   * @param {CoreContext} context - Context object
   * @param {DiscoveryOptions} options - Additional discovery options
   * @returns {DiscoveryResponse} Discovery response
   */
  public async getSessionFromResponse(
    response: DiscoveryResponse,
    source: DiscoverySource,
    context: CoreContext,
    options: DiscoveryOptions): Promise<DiscoveryResponse> {
    if (response.session && options.sessionConfig && source.includesSession) {
      const {session: id, sessionExpires: expires = 0} = response;
      // Got session.
      const session = await this.setSession({expires, id}, context, options);
      // Pass the expires downstream so we don't need to get it from local storage.
      response.sessionExpires = session.expires;
    }
    return response;
  }

  /**
   * Returns a one way hash of the session scope.
   * @param {CoreContext} context - Context object
   * @param {SessionConfig} sessionConfig - Application Session configuration
   * @returns {string} hash
   * @private
   */
  private getSessionHash(context: CoreContext, sessionConfig: SessionConfig): string {
    const {scope} = sessionConfig;
    const {userId} = context.imsProfile || {};
    const sessionInfo: {contextId?: string; imsOrgId?: string; userId?: string} = {userId};
    scope === SessionScope.USER || (sessionInfo.imsOrgId = context.imsOrg);
    scope === SessionScope.CONTEXT && (sessionInfo.contextId = context.contextId);

    return hashString(JSON.stringify(sessionInfo)).toString();
  }

  /**
   * Stores session data in the discovery cache.
   * @param {Session} session - Session object
   * @param {CoreContext} context - Context object
   * @param {DiscoveryOptions} options - Discovery Options
   */
  public async setSession(session: Session, context: CoreContext, options: DiscoveryOptions): Promise<Session> {
    const {appId, sessionConfig} = options;
    if (!sessionConfig) {
      throw new Error('Session config not provided');
    }
    if (!session.expires) {
      if (!sessionConfig.defaultTTL) {
        throw new Error('Session expiry not provided');
      }
      session.expires = Date.now() + sessionConfig.defaultTTL * 1000;
    }
    const hash: string = this.getSessionHash(context, sessionConfig);
    const key = sessionConfig.multiSessions ? `${appId}/session-${hash}` : `${appId}/session`;
    const cacheSession: CachedSession = {hash, ...session};
    await localCache.set(key, cacheSession);
    return session;
  }

  /**
   * Invalidates cached session (If available and matching)
   * @param {Session} session - Session object
   * @param {CoreContext} context - Context object
   * @param {DiscoveryOptions} options - Discovery Options
   */
  public async invalidateSession(session: Session, context: CoreContext, options: DiscoveryOptions): Promise<void> {
    const {appId, sessionConfig} = options;
    if (!sessionConfig) {
      throw new Error('Session config not provided');
    }
    const hash: string = this.getSessionHash(context, sessionConfig);
    const key = sessionConfig.multiSessions ? `${appId}/session-${hash}` : `${appId}/session`;
    const cachedSession: CachedSession|undefined = await localCache.get(key) as CachedSession;
    // Only invalidate if IDs match.
    if (cachedSession?.id === session.id) {
      await localCache.remove(key);
    }
  }

  /**
   * Reads session data from discovery cache (If available)
   * @param {CoreContext} context - Context object
   * @param {DiscoveryOptions} options - Discovery Options
   * @param {DiscoverySource} source - Solution discovery source definition
   * @param {DiscoveryService} discoveryService - Discovery Service
   * @returns {Session|undefined} Session object (If available)
   */
  public async getSession(
    context: CoreContext,
    options: DiscoveryOptions,
    source?: DiscoverySource,
    discoveryService?: DiscoveryService
  ): Promise<Session|undefined> {
    const {appId, sessionConfig} = options;
    if (!sessionConfig) {
      return;
    }
    const hash: string = this.getSessionHash(context, sessionConfig);
    const key = sessionConfig.multiSessions ? `${appId}/session-${hash}` : `${appId}/session`;
    let session: CachedSession|undefined = await localCache.get(key) as CachedSession;
    if (session && session.hash === hash) {
      // Remove hash before sending back
      session = {...session};
      delete session.hash;
      return session;
    }

    if (sessionConfig.getFromDiscovery && source && discoveryService) {
      // Skip cache if running discovery to get a session.
      const sessionOptions = {...options, sessionRequest: true};
      const {session: id, sessionExpires: expires} = await discoveryService.getSourceFromDiscovery(source, context, sessionOptions);
      if (id && expires) {
        return {expires, id};
      }
    }
  }
}

const sessionService = new SessionService();

export default sessionService;

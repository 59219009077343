/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import Emitter from '@exc/emitter';
import metrics, {Level} from '@adobe/exc-app/metrics';

export const CONNECTION_FAILURE_THRESHOLD = 4;
export const CONNECTION_UNSTABLE_THRESHOLD = 30000; // 30 seconds of instibility is too much

export class ConnectionMonitorService extends Emitter {
  private monitorCount = 0; // To count disconnects and reconnects
  private readonly metrics = metrics.create('exc.core.services.ConnectionMonitorService');
  private status = 'connected';

  constructor() {
    super();
    window.addEventListener('offline', () => this.raiseCount(true));
    window.addEventListener('online', () => this.raiseCount());
  }

  private lowerCount(): void {
    this.monitorCount--;
    // If we are out of change events to process, then update the banner state
    if (!this.monitorCount) {
      if (!navigator.onLine) {
        this.metrics.event('ConnectionMonitor.offline', {counter: this.monitorCount}, {level: Level.ERROR});
        this.updateStatus('offline');
      } else {
        this.metrics.event('ConnectionMonitor.online', {counter: this.monitorCount}, {level: Level.WARN});
        this.updateStatus('connected');
      }
    }
  }

  private raiseCount(disconnect = false): void {
    setTimeout(() => this.lowerCount(), CONNECTION_UNSTABLE_THRESHOLD);
    this.monitorCount++;
    if (disconnect) {
      this.metrics.event('ConnectionMonitor.disconnect', {counter: this.monitorCount}, {level: Level.WARN});
      if (this.monitorCount >= ((CONNECTION_FAILURE_THRESHOLD * 2) - 1)) {
        // The network has disconnected and reconnected and disconnected enough
        // times within our rolling time window to be unstable
        this.metrics.event('ConnectionMonitor.unstable', {counter: this.monitorCount}, {level: Level.ERROR});
        this.updateStatus('unstable');
      }
    }
  }

  private updateStatus(status: string): void {
    // Check that this is an actual state change or we could reverse an action from the dismiss button
    if (this.status === status) {
      // No change to emit
      return;
    }
    this.status = status;
    this.emit(status);
  }
}

export default new ConnectionMonitorService();

/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {AppSandbox, WorkspaceMenu} from '../models/AppSandbox';
import {getPathWithoutTenant, getUpdatedPath} from '@exc/url';
import type {NavHeading, NavItem} from '@adobe/exc-app/sidenav';
import type {NavItem as NavItemDeprecated} from '@adobe/exc-app/sidebar';

/**
 * Determines which base to use for the path based on whether the
 * `base` property is defined in the route config. This will be the default
 * instead of `this.basePath` unless it's not defined. This is for cases when
 * multiple configs are needed but need a single base. For example, Target has
 * 5 route configs (`/target/audiences`, etc) but really only want `/target`
 * to be the base.
 * @returns The base path to use.
 */
const getBase = ({basePath, solutionConfig: {base}}: AppSandbox) => base ? getUpdatedPath(basePath, base) : basePath;

const cleanUrl = (url: string | undefined, app: AppSandbox) => {
  if (!url) {
    return '';
  }
  return /^https?:/.test(url) ? url : getPathWithoutTenant(`${getBase(app)}${url}`);
};

export const cleanSidenavUrls = (menu: (NavItem | NavHeading | NavItemDeprecated)[], app: AppSandbox): void => {
  for (const item of menu) {
    if ('url' in item && item.url) {
      item.url = item.absolutePath ? item.url : cleanUrl(item.url, app);
    } else if ('children' in item && item.children) { // nested children items
      cleanSidenavUrls(item.children, app);
    } else if ('items' in item) { // items nested in headers
      cleanSidenavUrls(item.items, app);
    } else if ('selectOn' in item) {
      item.selectOn = (item.selectOn as string[]).map(path => cleanUrl(path, app)); // item selector paths
    }
  }
};

const generateUid = (name: string, parentUid?: string): string => parentUid ? `${parentUid}_${name}` : name;

/**
 * Updates the workspaces URLs.
 * @param workspace - Workspace Object
 * @param app - App Sandbox object.
 * @param parent Internal - Workspace parent object (This method is used recursively)
 */
export const updateWorkspaceURL = (workspace: WorkspaceMenu, app: AppSandbox, parent?: WorkspaceMenu) => {
  if (!workspace.menu) {
    workspace.uid = generateUid(workspace.name, (parent || {}).uid);
    workspace.url = cleanUrl(workspace.url, app);
    return;
  }

  workspace.uid = generateUid(workspace.name, (parent || {}).uid);
  // Ensure that the workspace url is set to at least an empty string. Not
  // setting the url tells us that the workspace should not be added to the
  // workspace menu as the first item.
  workspace.url = cleanUrl(workspace.url, app);

  workspace.menu.forEach(ws => {
    ws.uid = generateUid(ws.name, workspace.uid);
    ws.url = cleanUrl(ws.url, app);
    ws.menu && updateWorkspaceURL(ws, app, workspace);
  });

  // Clicking on the top-level workspace opens the menu. If the workspace url
  // is set, we add it as the first item in the menu so that it can be
  // navigated to.
  workspace.url && workspace.menu.unshift({
    name: workspace.name,
    uid: generateUid(workspace.name, workspace.uid),
    url: workspace.url
  });
};

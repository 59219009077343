/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2019 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
import type {IframePerformance, LegacyTiming, NavigationTimingReduced} from '@exc/shared';
import {isWorkerActivated} from './workerUtils';
import type {LoadTimes, LoadTimesMetrics, PerformanceRecord} from '@adobe/exc-app/page';
import {THUNDERBIRD} from '../models/Solution';

export interface PerformanceParams {
  appDone: boolean;
  appId: string;
  firstRender: boolean;
  iframePerformance: string;
  lastAppId?: string;
  spaAppId?: string;
  src: string | URL;
  thunderbird?: THUNDERBIRD;
  start?: number;
  version?: string;
}

interface NavigationTiming extends NavigationTimingReduced {
  legacyTiming?: boolean;
}

const navProperties = ['domInteractive', 'fetchStart', 'loadEventStart', 'responseEnd'];
/**
 * Gets navigation timing metrics from window.performance.
 * Done either through a navigation entry or the legacy timing record.
 * @param nav - Navigation performance entry
 * @param timing - Performance timing, legacy interface if entry not available.
 * @returns {{}|{navigation metrics}}
 */
function getNavigationTiming(nav: undefined | NavigationTiming, timing: LegacyTiming): NavigationTiming {
  let finalNav = nav;
  if (!finalNav && timing.navigationStart) {
    // Use legacy timing object (Safari).
    const legacyNav = {...timing, legacyTiming: true};
    navProperties.forEach(property => {
      if (legacyNav[property as keyof LegacyTiming]) {
        legacyNav[property as keyof LegacyTiming] = legacyNav[property as keyof LegacyTiming] - timing.navigationStart;
      }
    });
    finalNav = legacyNav;
  }
  const {domInteractive, fetchStart, legacyTiming, loadEventStart, responseEnd} = finalNav as NavigationTiming;
  return {
    domInteractive,
    fetchStart,
    legacyTiming,
    loadEventStart,
    responseEnd
  };
}

/**
 * Extracts performance data from metrics sent by the iframe.
 * @param loadTimes - Performance timings
 * @param start - App load start timestamp
 * @param done Page done timestamp as recorded by iframe
 * @param iframe performance entries
 * @param timeOrigin iframe navigation start (Time origin) timestamp
 * @param timing iframe legacy timing object
 * @returns Performance metrics (key/value) object
 */
function addIframeMarks(loadTimes: LoadTimes, {done = 0, entries, timeOrigin, timing}: IframePerformance, start = 0) {
  const {timing: shellTiming} = performance;
  const shellTimeOrigin = performance.timeOrigin || shellTiming.navigationStart;
  const frameTimeOrigin = timeOrigin || timing?.navigationStart;
  /*
    Since the Shell frame and iframe started navigations at different times,
    we need to normalize iframe times by adding the delta:
    iframe navigation time - shell frame navigation time.
   */
  const originDelta = frameTimeOrigin - (shellTimeOrigin + start);
  const withDelta = (metric: number) => metric + originDelta;

  if (shellTimeOrigin && originDelta > 0 && entries) {
    let nav: PerformanceNavigationTiming | undefined;
    entries.forEach(entry => {
      const {duration, entryType, startTime, name} = entry;
      if (entryType === 'paint') {
        loadTimes[`iframe-${name}` as keyof LoadTimesMetrics] = withDelta(startTime);
      } else if (entryType === 'navigation' && !nav) {
        nav = entry as PerformanceNavigationTiming;
      } else if (entryType === 'resource') {
        loadTimes.rt_script_start = withDelta(startTime);
        loadTimes.rt_script_end = withDelta(startTime + duration);
      } else if (!loadTimes[name as keyof LoadTimes] && /^rt/.test(name)) {
        loadTimes[name as keyof LoadTimesMetrics] = withDelta(startTime);
      }
    });

    loadTimes.rt_page_done = withDelta(done);
    const navTimings = getNavigationTiming(nav, timing);
    if (navTimings) {
      loadTimes.iframeFetchStart = withDelta(navTimings.fetchStart);
      loadTimes.iframeDomInteractive = withDelta(navTimings.domInteractive);
      loadTimes.iframeResponseEnd = withDelta(navTimings.responseEnd);
    }
  }
}

/**
 * Adds Unified Shell performance data.
 * @param loadTimes - Performance timings
 * @param start - App load start timestamp
 * @param src IFrame source
 */
function addShellPerformance(loadTimes: LoadTimes, start: number, src: string) {
  const iframeEntries = performance.getEntries().filter(({startTime}) => startTime > start)
    .filter(e => (/^us/.test(e.name) || e.name.includes('paint') ||
      e.name.includes('/token?jslVersion=v2') || (e.name === src && (e as PerformanceResourceTiming)?.initiatorType === 'iframe'))
    ) as PerformanceResourceTiming[];
  iframeEntries.forEach(({duration, initiatorType, name, startTime}) => {
    startTime -= start;
    if (initiatorType === 'iframe') {
      name = 'us_iframe_loaded';
    }
    if (name.includes('/token?jslVersion=v2')) {
      name = 'us_check_token_start';
      loadTimes['us_check_token_end'] = startTime + duration;
    }
    if (!loadTimes[name as keyof LoadTimesMetrics]) {
      loadTimes[name as keyof LoadTimesMetrics] = startTime;
    }
  });
}

/**
 * Aggregates performance data before sending to EIM.
 * @param params - Performance parameters
 * @returns Performance metrics (key/value pairs).
 */
export function getPerformanceMarks(params: PerformanceParams): PerformanceRecord {
  const {
    appDone,
    appId,
    firstRender,
    iframePerformance: iframeStr,
    lastAppId,
    spaAppId = 'n/a',
    src = '',
    thunderbird,
    start = 0,
    version = ''
  } = params;
  const {region} = window.config || {};
  const baseData = {
    appId,
    region,
    serviceWorker: isWorkerActivated(),
    spaAppId,
    thunderbird,
    valid: true,
    version
  };

  try {
    const iframePerformance = JSON.parse(iframeStr || '{}');
    const us_page_done = performance.now() - start;
    let loadTimes: LoadTimes = {rt_page_done: us_page_done, us_page_done};

    addShellPerformance(loadTimes, start, src.toString());
    // Add iframe performance times
    if (iframePerformance) {
      addIframeMarks(loadTimes, iframePerformance, start);
      if (loadTimes.iframeFetchStart && loadTimes.iframeFetchStart < 0) {
        baseData.valid = false;
      }
    }

    if (firstRender) {
      // First app render in this session.
      // need to combine Unified Shell and App performance data.
      let loadType = loadTimes.us_shell_done ? 'reload' : 'post-login';
      const nav = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
      loadTimes = {...loadTimes, ...getNavigationTiming(nav, performance.timing)};
      loadTimes.us_shell_isloginflow_done && (loadTimes.us_shell_done = loadTimes.us_shell_isloginflow_done);
      if (!loadTimes.us_shell_done) {
        return {...baseData, error: 'Page done before Shell done'};
      }
      if (loadTimes.us_errormessage) {
        // Override load type so we know that load contained an error as this will
        // mess up the performance numbers.
        loadType = `${loadType}-with-error`;
      }
      return {...baseData, loadTimes, loadType};
    }

    // Not first app load in session but first app load for this Sandbox instance.
    if (lastAppId && !appDone) {
      return {...baseData, lastAppId, loadTimes, loadType: 'switch'};
    }

    // Second (Or more) time this Sandbox instance has loaded its app.
    // As we can't pinpoint the exact timing when inner navigation starts,
    // not recording performance metrics in this case.
    return {...baseData, loadType: 'inner-nav'};
  } catch (err) {
    return {...baseData, error: `Failed to get performance data: ${err}`};
  }
}
